import React from 'react';
import ClassicTheme
 from './ClassicTheme/ClassicTheme';

import FramedTheme from './FramedTheme/FramedTheme';
import ParallelTheme from "./ParallelTheme/ParallelTheme";
  import TopClassicTheme from './TopClassicTheme/TopClassicTheme';
 const GalleryRender = ({ themeClientData }) => {
     //console.log(themeClientData)
    const currentTheme = themeClientData.project_info.PROJECT_THEME;
  switch (currentTheme) {
    case "Classic":
      return (
        <>
          <ClassicTheme ClientData={themeClientData} />
        </>
      );
    case "Framed":
      return (
        <>
          <FramedTheme ClientData={themeClientData} />
        </>
      );
    case "Parallel":
      return (
        <>
          <ParallelTheme ClientData={themeClientData} />
        </>
      );
    case "TopClassic":
      return (
        <>
          <TopClassicTheme ClientData={themeClientData} />
        </>
      );
    
    default:
      return null; // Or any default component you wish to render
  }
};

export default GalleryRender;