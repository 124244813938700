import { getUrl, uploadData } from "aws-amplify/storage";
export const getSignedUrl = async (key) => {
  return await getUrl({
    key: key,
    options: {
      accessLevel: "private", // can be 'private', 'protected', or 'guest' but defaults to `guest`
      validateObjectExistence: false, // defaults to false
      expiresIn: 3600, // validity of the URL, in seconds. defaults to 900 (15 minutes) and maxes at 3600 (1 hour)
    },
  });
};

export const uploadFile = async (project_name, file) => {
  let filename = file.name;
  try {
    const uploadResult = await uploadData({
      key: project_name + "/" + filename,
      data: file,
      options: {
        accessLevel: "private", // defaults to `guest` but can be 'private' | 'protected' | 'guest'
        onProgress: (progressEvent,key) => {
          
        }, // Optional progress callback.
      },
    });
    // 
    return uploadResult;
  } catch (error) {
    // 
  }
};

export const uploadImage = async (project_name,key, file) => {
  let filename = key;
  try {
    const uploadResult = await uploadData({
      key: project_name + "/" + filename,
      data: file,
      options: {
        accessLevel: "private", // defaults to `guest` but can be 'private' | 'protected' | 'guest'
        onProgress: (progressEvent,key) => {
          
        }, // Optional progress callback.
      },
    }).result;
    // 
    return uploadResult;
  } catch (error) {
    // 
  }
};



export const uploadTempFile = async (project_name,file) => {
  let filename = file.name;
  let key = project_name + "/temp/"  + filename;
  if ( !project_name) {
    
    return;
  }
  try {
    const uploadResult = await uploadData({
      key: project_name + "/temp/"  + filename,
      data: file,
      options: {
        accessLevel: "private", // defaults to `guest` but can be 'private' | 'protected' | 'guest'
      },
    });
    
  } catch (error) {
    
  }
};
