import React, { useState, useEffect, useRef, useMemo } from "react";
import "./SideGalleryDesigner.css";
import styles from "./SideGalleryDesigner.module.css";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { SketchPicker } from "react-color";
import { useNavigate } from "react-router-dom";
import { createUserProject, getUserProjects } from "../../api/projects";
import { uploadImage } from "../../api/storage";
import { StorageImage } from "@aws-amplify/ui-react-storage";
import { MdCloudUpload } from "react-icons/md";
import Resizer from "react-image-file-resizer";
import { nanoid } from "nanoid";
import { Alert } from "@aws-amplify/ui-react";
import { getIdentityId } from "../../api/user";
function SideGalleryDesigner({
  setThemeClientData,
  setIsNewProject,
  themeClientData,
  isNewProject,
  defaultThemeData,
  setSelectedTheme,
  setIsImageUploader,
  isImageUploader,
}) {
  const [projectValidationMessage, setProjectValidationMessage] =
    useState(null);

  // Only used for project name validation
  const [project_name_input, setProjectNameInput] = useState("");
  // const [isNewProject, setIsNewProject] = useState(isNewProject);
  const TEMPKEY = "TEMP/";
  let navigate = useNavigate();
  const routeChange = (path) => {
    let newPath = path;
    navigate(newPath);
  };

  const closeGalleryDesigner = () => {
    // Move page to the dashboard
    routeChange("/dashboard");
  };

  const handleThemeChange = (e) => {
    setSelectedTheme(e.target.text);
  };

  const localClientDataRef = useRef();
  const projectDetailsRef = useRef();

  const [projectDetails, setProjectDetails] = React.useState([
    {
      PAGE_NAME: "Project Details",
      PROJECT_NAME: {
        customizeType: "text",
        text: "Project Name",
        value: "",
        text_lines: 1,
        required: true,
      },
    },
  ]);

  useEffect(() => {
    projectDetailsRef.current = projectDetails;
  }, [projectDetails]);

  useEffect(() => {
    localClientDataRef.current = themeClientData;
    setProjectDetails([
      {
        PAGE_NAME: "Project Details",
        PROJECT_NAME: {
          customizeType: "text",
          text: "Project Name",
          value: themeClientData.project_info.PROJECT_NAME,
          text_lines: 1,
          required: true,
        },
      },
    ]);
  }, []);

  useEffect(() => {
    localClientDataRef.current = themeClientData;
  }, [themeClientData]);

  const handleImageChange = (e) => {
    const input_form = document.getElementById(
      "img_input_" + e.target.id.replace("cloud_", "")
    );
    input_form.click();
  };

  const handleImageChangeInputChange = async (e) => {
    // If file is not selected, return
    if (!e.target.files[0]) {
      return;
    }
    let file = e.target.files[0];
    const file_key = e.target.id.replace("img_input_", "");
    const filename = TEMPKEY + file.name;
    if (!file) {
      return;
    }
    const resizeFile = await new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        3200,
        3200,
        "JPEG",
        90,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

    await uploadImage(
      themeClientData.project_info.PROJECT_NAME,
      filename,
      resizeFile
    ).then((response) => {
      let imgElement = document.getElementById(file_key);
      imgElement.setAttribute("data-img-key", file.name);
      imgElement.setAttribute("data-img-key", file_key);
      imgElement.setAttribute("data-img-key", filename);

      imgElement.setAttribute("src", URL.createObjectURL(file));
      // wait 5 seconds
      setTimeout(() => {
        updateThemeData();
      }, 200);
    });
  };

  async function updateThemeData(e) {
    if (isNewProject) {
      setProjectNameInput(e.target.value);
      const text = e.target.value;
      // validate that there are no speial characters in the text
      // const regex = /^[a-zA-Z0-9_ ]*$/;
      const regex = /^[a-zA-Z0-9_-]*$/;
      if (!regex.test(text)) {
        setProjectValidationMessage(
          "Please use only letters, numbers, underscores, dashes. No spaces or special characters."
        );
        // Remove the last character from the string
        // e.target.value = e.target.value.slice(0, -1);
        return;
      }

      setProjectValidationMessage(null);

      return;
    }

    const clientData = localClientDataRef.current;
    // Update Theme Data

    for (const index in clientData.theme_info) {
      let theme = clientData.theme_info[index];
      for (const [key, value] of Object.entries(theme)) {
        if (value?.customizeType === "text") {
          clientData.theme_info[index][key].value =
            document.getElementById(key).value;
        }
        if (value?.customizeType === "color") {
          let svgElement = document.getElementById(key);

          let fillColor = svgElement.getAttribute("fill");
          // clientData.theme_info[index][key].value = fillColor;
          //
          // svgElement.setAttribute("fill", fillColor);
        }
        if (value?.customizeType === "image") {
          let imgElement = document.getElementById(TEMPKEY + key);
          // let imgKey = imgElement.getAttribute("data-img-key");
          let imgKey = imgElement.getAttribute("data-img-key");

          if (!imgKey) {
            // return;
          }

          clientData.theme_info[index][key].value = imgKey;
        }
      }
    }

    if (isNewProject) {
      for (const index in projectDetails) {
        if (projectDetails[index].PROJECT_NAME) {
          projectDetails[index].PROJECT_NAME.value = document
            .getElementById("PROJECT_NAME")
            .value.toLowerCase();
        }
      }

      setProjectDetails([...projectDetails]);
    }

    localClientDataRef.current = clientData;
    projectDetailsRef.current = projectDetails;

    setThemeClientData({ ...clientData });
  }

  function textIcon(text) {
    return <textarea>Hello there, this is some text in a text area</textarea>;
  }

  const [color, setColor] = useState("");
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [activeColorPicker, setActiveColorPicker] = useState(null);

  function imageSelection(item, key) {
    // Test if item.exists exists and is true

    return (
      <div
        className={`${styles.menuHeader} d-flex flex-column flex-md-row justify-items-between gap-3 gap-md-0 justify-content-md-between align-items-center justify-items-center  ${styles.menuHeader}`}
      >
        <span>{item.text}</span>
        <div className={styles.ImageIcon}>
          <input
            type="file"
            style={{ display: "none" }} // Hide the file input
            id={"img_input_" + TEMPKEY + key} // Attach the id
            // ref={fileInputRef} // Attach the reference
            onChange={handleImageChangeInputChange}
          />
          <>
            <div
              style={{ height: "35px", width: "35px" , cursor: "pointer"}}
              id={`cloud_${TEMPKEY + key}`}
              onClick={handleImageChange}
              
            >
              <svg
                style={{
                  height: "35px",
                  width: "35px",
                  pointerEvents: "none",
                  display: item.value === "default" ? "" : "none",
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-file-earmark-arrow-up-fill"
                viewBox="0 0 16 16"
              >
                <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M6.354 9.854a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 8.707V12.5a.5.5 0 0 1-1 0V8.707z" />
              </svg>
            </div>
            {/* <MdCloudUpload
              className={styles.cloudIcon}
              id={`cloud_${TEMPKEY + key}`}
              onClick={handleImageChange}
              style={{
                display: item.value === "default" ? "" : "none",
                fontSize: "5em", // Change this value to make the icon bigger or smaller
                // marginRight: '10px', // Change this value to move the icon left or right
              }}
            /> */}
            {item.value === "default" ? (
              <StorageImage
                data-img-key={`${item.value}`}
                id={TEMPKEY + key}
                className={`${styles.backgroundImage}`}
                onClick={handleImageChange}
                accessLevel={themeClientData.project_info.PROJECT_STATUS}
                style={{ display: item.value === "default" ? "none" : "block" }}
              />
            ) : (
              <StorageImage
                data-img-key={`${item.value}`}
                id={TEMPKEY + key}
                className={`${styles.backgroundImage}`}
                imgKey={`${themeClientData.project_info.PROJECT_NAME}/${item.value}`}
                onClick={handleImageChange}
                accessLevel={themeClientData.project_info.PROJECT_STATUS}
                style={{ display: item.value === "default" ? "none" : "block" }}
              />
            )}
          </>
        </div>
      </div>
    );
  }
  function colorSelection(item, key) {
    const handleChange = (newColor) => {
      setColor(newColor.hex);
      item.value = newColor.hex;
      updateThemeData();
    };

    return (
      <div
        className={`${styles.menuHeader}  d-flex justify-content-center gap-3 gap-md-0 justify-content-md-between align-items-center ${styles.menuHeader}`}
        onClick={() => setActiveColorPicker(key)}
        onMouseLeave={() => setActiveColorPicker(null)}
      >
        <span>{item.text}</span>
        <div className={styles.colorIcon}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill={item.value}
            id={key}
          >
            <path d="M4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3Z"></path>
          </svg>
          {activeColorPicker === key ? (
            <div style={{ position: "relative", zIndex: "2", left: "-196px" }}>
              <SketchPicker color={color} onChange={handleChange} />
            </div>
          ) : null}
        </div>{" "}
      </div>
    );
  }
  function textSelection(item, key) {
    // Test if item.required exists and is true
    return (
      <div
        className={`${styles.menuHeader}    d-flex flex-column align-items-center align-items-md-start  `}
      >
        <span className="text-center text-md-start ">{item.text} </span>
        <div
          className={`${styles.textContainer}  justify-content-center gap-3 gap-md-0 align-items-center pt-2  `}
        >
          {" "}
          <div className="form-group">
            <textarea 
              style={{ fontSize: "14px" }}
              onChange={updateThemeData}
              className="form-control  alegreya-sans-regular"
              id={key}
              required={item.required}
              defaultValue={item.value}
              rows={item.text_lines ? item.text_lines : 2}
            ></textarea>
          </div>
        </div>
      </div>
    );
  }

  const handleSaveProject = async () => {
    const identityId = await getIdentityId();
    projectDetailsRef.current[0].IDENTITY_ID = identityId;

    if (isNewProject) {
      projectDetailsRef.current[0].PROJECT_NAME.value = document
        .getElementById("PROJECT_NAME")
        .value.toLowerCase();
      themeClientData.project_info.IDENTITY_ID =
        projectDetailsRef.current[0].IDENTITY_ID;
      setThemeClientData({ ...themeClientData });

      const projects = await getUserProjects();

      if (projects.Items) {
        // Confirm that project doesnt already exist
        for (let p of projects.Items) {
          if (
            p.project_info.PROJECT_NAME ===
            projectDetailsRef.current[0].PROJECT_NAME.value.toLowerCase()
          ) {
            setProjectValidationMessage("Project already exists");
            return;
          }
        }
      }
    }

    await createUserProject(
      projectDetailsRef.current[0].PROJECT_NAME.value,
      themeClientData.project_info.PROJECT_THEME,
      localClientDataRef.current.theme_info,
      projectDetailsRef.current[0].IDENTITY_ID
    ).then((response, err) => {
      if (response.Error) {
        if (
          response.Error ===
          "Invalid project name. Only alphanumeric characters, underscores, and hyphens are allowed."
        ) {
          setProjectValidationMessage(response.Error);
          return;
        }
        console.error("Error creating project", response.Error);
        closeGalleryDesigner();
      }

      if (isNewProject) {
        themeClientData.project_info.PROJECT_NAME =
          projectDetailsRef.current[0].PROJECT_NAME.value;
        setThemeClientData({ ...themeClientData });
        setIsNewProject(false);
      } else {
        closeGalleryDesigner();
      }
    });
  };

  const ButtonSet = () => {
    return (
      <div
        className={`${styles.buttonContainer} d-flex justify-content-center gap-1`}
      >
        <div
          onClick={closeGalleryDesigner}
          className={`btn ${styles.projectButtons} discardButton`}
        >
          Discard
        </div>
        <div
          onClick={handleSaveProject}
          className={`btn ${styles.projectButtons} SaveButton`}
        >
          Save
        </div>
      </div>
    );
  };

  return (
    <div className="dashboard-container">
      <div className={`layout has-sidebar fixed-sidebar  fixed-header`}>
        <aside id="sidebar" className="sidebar   break-point-sm has-bg-image">
          {/* <a id="btn-collapse" className=" sidebar-collapser ">
            <i className="ri-arrow-left-s-line"></i>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path>
            </svg>
          </a> */}
          {/* <div className="image-wrapper">
            <img src="assets/images/sidebar-bg.jpg" alt="sidebar background" />
          </div> */}
          <div className="gallery-sidebar-layout ">
            {/* <div className="sidebar-header d-flex justify-items-center align-items-center">
              <div className="pro-sidebar-logo">
                <Link to="/dashboard">
                  <h5 className={`${styles.sideBarHeader} ibarra-real-nova-regular`}>
                    Gallery Designer
                  </h5>
                  <hr style={{backgroundColor: "white"}} />
                </Link>
              </div>
            </div> */}
            <div className="sidebar-content alegreya-sans-sc-regular">
              {isNewProject
                ? projectDetails.map((item, index) => {
                    return (
                      <div key={index} className="d-flex  flex-column gap-3 ">
                        <hr
                          style={{ marginTop: "0px", marginBottom: "1rem" }}
                        />
                        <p className={`${styles.menuHeaderThemeText} `}>
                          {item.PAGE_NAME}
                        </p>
                        {Object.entries(item).map(([key, value]) => {
                          let valueText = "";
                          if (value && value.customizeType) {
                            switch (value.customizeType) {
                              case "color":
                                valueText = colorSelection(value, key);
                                break;
                              case "image":
                                valueText = imageSelection(value, key);
                                break;
                              case "text":
                                valueText = textSelection(value, key);
                                break;
                              default:
                                valueText = "";
                                break;
                            }
                          }
                          return <div key={key}>{valueText}</div>;
                        })}

                        {projectValidationMessage ? (
                          <div className="d-flex justify-items-center align-items-center">
                            <Alert
                              className={styles.alertBox}
                              isDismissible={false}
                              hasIcon={false}
                              variation="warning"
                            >
                              {projectValidationMessage}
                            </Alert>
                          </div>
                        ) : null}

                        <hr />
                      </div>
                    );
                  })
                : null}

              {isNewProject ? (
                !projectValidationMessage &&
                project_name_input !== "" ? null : null
              ) : (
                <>

                  <div className="d-flex flex-column gap-3">
                   
                  {!isImageUploader ? (
                   
                    <div
                      className={`${styles.menuHeaderThemeText}  d-flex flex-column  justify-content-center gap-3 justify-content-md-between align-items-center`}
                    >
                      <span className="alegreya-sans-sc-regular">
                        Theme Selection
                      </span>
                      <div
                        className={`${styles.themeIcon} ${styles.btnSuccess} `}
                      >
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="sucscess"
                            className={styles["theme-dropdown"]}
                          >
                            {/* {selectedTheme} */}
                            {themeClientData.project_info.PROJECT_THEME}
                          </Dropdown.Toggle>

                          <Dropdown.Menu className={styles["dropdown-menu"]}>
                            {defaultThemeData.map((item) => {
                              let theme = item.project_info.PROJECT_THEME;
                              return (
                                <Dropdown.Item
                                  href="#/action-1"
                                  className={styles["dropdown-item"]}
                                  key={"dropdown" + theme}
                                  onClick={handleThemeChange}
                                >
                                  {theme}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  
                ) : null}

                    {!isImageUploader && themeClientData.theme_info?.map((item, index) => {
                      // Loop through each {} object in item and check if order_num exists and then sort by order_num
                      // Loop through item
                      // if order_num exists then sort by order_num
                      let sortedArray = [];
                      let leftOverArray = [];
                      Object.entries(item).map(([key, value]) => {
                        let keyValue = key;
                        if (value.order_num !== undefined) {
                          if (!sortedArray[value.order_num]) {
                            sortedArray[value.order_num] = {
                              [keyValue]: value,
                            };
                          } else {
                            console.error("Duplicate order_num found");
                          }
                        } else {
                          leftOverArray.push({ [keyValue]: value });
                        }
                      });
                      // Remove any empty elements from the array
                      sortedArray = sortedArray.filter(function (el) {
                        return el != null;
                      });
                      // Add any left over elements to the end of the array
                      sortedArray = sortedArray.concat(leftOverArray);
                      // create new json object with sorted array
                      sortedArray.map((item, index) => {
                        Object.entries(item).map(([key, value]) => {});
                      });

                      return (
                        <div
                          key={index}
                          className="d-flex flex-column gap-3 justify-items-center align-items-center"
                        >
                          {/* <p className={`${styles.menuHeaderThemeText} `}>
                          {item.PAGE_NAME}
                        </p> */}
                          {sortedArray.map((item, index) => {
                            return Object.entries(item).map(([key, value]) => {
                              let valueText = "";
                              // Test if value is not null and mainText exists in the value
                              if (value && value.customizeType) {
                                switch (value.customizeType) {
                                  case "color":
                                    valueText = colorSelection(value, key);
                                    break;
                                  case "image":
                                    valueText = imageSelection(value, key);
                                    break;
                                  case "text":
                                    valueText = textSelection(value, key);
                                    break;
                                  default:
                                    valueText = "";
                                    break;
                                }
                              }
                              return (
                                <div style={{ minWidth: "100%" }} key={key}>
                                  {valueText}
                                </div>
                              );
                            });
                          })}
                        </div>
                      );
                    })}

                    <div className="d-flex flex-column  ">
                      {isImageUploader ? (
                        <div
                          onClick={() => setIsImageUploader(false)}
                          className={`btn ${styles.projectButtons} ${
                            styles.imageUploaderButton
                          } ${
                            isImageUploader ? styles.imageUploaderOpened : ""
                          }  justify-items-center align-items-center `}
                        >
                          Close Image Uploader
                        </div>
                      ) : (
                        <div
                          onClick={() => setIsImageUploader(true)}
                          className={`btn ${styles.projectButtons} ${styles.imageUploaderButton}`}
                        >
                          Upload Images
                        </div>
                      )}
                    </div>
                    <hr />
                  </div>
                </>
              )}

              {isNewProject ? (
                !projectValidationMessage && project_name_input !== "" ? (
                  <ButtonSet key={nanoid()} />
                ) : null
              ) : (
                <ButtonSet key={nanoid()} />
              )}
            </div>
          </div>
        </aside>
        <div id="overlay" className="overlay"></div>
      </div>
    </div>
            
  );
}

SideGalleryDesigner.defaultProps = {
  isMinimized: false,
};

export default SideGalleryDesigner;
