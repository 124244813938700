import React, { useEffect, useState, useRef } from "react";
import styles from "./ImageUploader.module.css";
import { withAuthenticator } from "@aws-amplify/ui-react";

// import {FileUploader} from '@aws-amplify/ui-react';
import DropZone from "./DropZone";
import Masonry from "react-masonry-css";
import { nanoid } from "nanoid";
import { Flex, Image } from "@chakra-ui/react";
import "@aws-amplify/ui-react/styles.css";
import { Tabs } from "@aws-amplify/ui-react";
import ImageUploaderGallery from "./ImageUploaderGallery";
function ImageUploader({ ClientData }) {
  const [uniqueKey, setUniqueKey] = useState(nanoid());
  return (
    <div className="bg-light alegreya-sans-sc-bold " style={{letterSpacing: "1.2px"}}>
      <Flex className="p-5 " direction="column" gap="2rem">
        <Tabs.Container defaultValue="1">
          <Tabs.List spacing="equal">
            <Tabs.Item  className="" value="1" onClick={() => setUniqueKey(nanoid())}>View Gallery</Tabs.Item>
            <Tabs.Item value="2">Upload Images </Tabs.Item>
          </Tabs.List>
          <Tabs.Panel value="1">
            {" "}
            <ImageUploaderGallery key={uniqueKey}
              project_name={ClientData.project_info.PROJECT_NAME}
            />
          </Tabs.Panel>
          <Tabs.Panel value="2">
            {" "}
            <div className={`${styles.dropZone}`}>
              <DropZone project_name={ClientData.project_info.PROJECT_NAME} />
            </div>
            <div className={styles.storageManagerContiner}></div>
          </Tabs.Panel>
        </Tabs.Container>
      </Flex>
    </div>
  );
}
export default withAuthenticator(ImageUploader);
