import React from "react";
import { useEffect, useState } from "react";
import logo from "./zmedia-header-logo.png";
import { useAuthenticator } from "@aws-amplify/ui-react";
import styles from "./Navbar.module.css-archive";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

function NavBar() {
  //   const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (authStatus === "authenticated") {
      setIsAuthenticated(true);
    }
  }, [authStatus]);

  async function handleSignOut() {
    try {
      await signOut();
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    } catch (error) {
      //console.log("error signing out: ", error);
    }
  }

  return (
    <>
      <section className={styles.navBarCustom}>
        <Navbar className={` ${styles.navbarButtonsContainer} `} expand="sm">
          <Navbar.Brand className={`navbar-brand ${styles.navHeader}`} href="/">
            Z-Media
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            style={{ color: "white", "marginRight": "10px" }}
          />{" "}
          <Navbar.Collapse
            id="responsive-navbar-nav "
            className={`justify-content-end`}
          >
            <Nav className="ml-auto">
              <Nav.Link
                href="/"
                className={`btn nav-link ${styles.navLink} d-none d-md-block`}
              >
                Home
              </Nav.Link>
              <Nav.Link
                href="/contact"
                className={`btn nav-link ${styles.navLink}`}
              >
                Contact Us
              </Nav.Link>
              <Nav.Link
                href="/pricing"
                className={`btn nav-link ${styles.navLink}`}
              >
                Pricing
              </Nav.Link>
              <Nav.Link
                href="/about"
                className={`btn nav-link ${styles.navLink}`}
              >
                About
              </Nav.Link>
              {isAuthenticated ? (
                <Nav.Link
                  href="/dashboard"
                  className={`btn nav-link ${styles.navLink}`}
                >
                  Dashboard
                </Nav.Link>
              ) : null}
              <Nav.Link
                href="/account"
                className={`btn nav-link ${styles.navLink}`}
              >
                Account
              </Nav.Link>
              {isAuthenticated ? (
                <button
                  href="/"
                  className={`btn nav-link ${styles.navLink}`}
                  onClick={handleSignOut}
                >
                  Logout
                </button>
              ) : (
                <Nav.Link
                  href="/dashboard"
                  className={`btn nav-link ${styles.navLink}`}
                >
                  Login
                </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </section>
    </>
  );
}

export default NavBar;
