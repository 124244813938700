import { ReactNode } from 'react';
import Shaheer from './shaheer.png';
import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Container,
  Avatar,
  useColorModeValue,
} from '@chakra-ui/react';

const Testimonial = ({ children }) => {
  return <Box>{children}</Box>;
};

const TestimonialContent = ({ children }) => {
  return (
    <Stack
    className='alegreya-sans-regular'
      bg={useColorModeValue('white', 'gray.800')}
      boxShadow={'lg'}
      p={8}
      rounded={'xl'}
      align={'center'}
      pos={'relative'}
      _after={{
        content: `""`,
        w: 0,
        h: 0,
        borderLeft: 'solid transparent',
        borderLeftWidth: 16,
        borderRight: 'solid transparent',
        borderRightWidth: 16,
        borderTop: 'solid',
        borderTopWidth: 16,
        borderTopColor: useColorModeValue('white', 'gray.800'),
        pos: 'absolute',
        bottom: '-16px',
        left: '50%',
        transform: 'translateX(-50%)',
      }}>
      {children}
    </Stack>
  );
};

const TestimonialHeading = ({ children }) => {
  return (
    <h3 as={'h3'} fontSize={'xl'} className='alegreya-sc-regular' style={{color:'#00057B'}}>
      {children}
    </h3>
  );
};

const TestimonialText = ({ children }) => {
  return (
    <Text
      textAlign={'center'}
      color={useColorModeValue('gray.600', 'gray.400')}
      fontSize={'sm'}>
      {children}
    </Text>
  );
};

const TestimonialAvatar = ({
  src,
  name,
  title,
}) => {
  return (
    <Flex align={'center'} mt={8} direction={'column'}>
      <Avatar src={src} alt={name} mb={2} />
      <Stack spacing={-1} align={'center'}>
        <Text fontWeight={600}>{name}</Text>
        <Text fontSize={'sm'} color={useColorModeValue('gray.600', 'gray.400')}>
          {title}
        </Text>
      </Stack>
    </Flex>
  );
};

export default function WithSpeechBubbles() {
  return (
    <div className='opp'  >


    <Box 
    // bg={useColorModeValue('gray.100', 'gray.700')}
    >
      <Container maxW={'7xl'}  as={Stack} spacing={12}>
       
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 10, md: 4, lg: 10 }}
          paddingLeft={10}
          paddingRight={10}
          >
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>  New technology</TestimonialHeading>
              <TestimonialText>
              The AI technology seamlessly identifies guests' photos, making it effortless for everyone to find and cherish their memories. Kudos to the Z-Media team for creating such a brilliant solution!
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={
                Shaheer
              }
              name={'Shaheer Siddiqi'}
              title={'Event Coordinator'}
            />
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Intuitive Design</TestimonialHeading>
              <TestimonialText>
              Z-Media has significantly enhanced our approach to reminiscing over special moments. Whether it's a wedding or a corporate event, their platform has seamlessly integrated into our photo-sharing routine.,

              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={
                'https://i.imgur.com/w2CKRB9.jpg'
              }
              name={'Mark Smith'}
              title={'Wedding Planner'}
            />
          </Testimonial>

          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Mindblowing Service</TestimonialHeading>
              <TestimonialText>
              Z-Media's innovation in photo recognition is a game-changer for preserving precious memories. As a frequent user, I've experienced firsthand how effortlessly it identifies and organizes guests' photos, adding a layer of convenience to our gatherings.,
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={
                'https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=100&q=80'
              }
              name={'Veera Duncan'}
              title={'Photographer'}
            />
          </Testimonial>
        </Stack>
      </Container>
    </Box>
    </div>

  );
}