import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";

async function getToken() {
    try {
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
      return idToken;
    } catch (err) {
      
    }
  }

export { getToken };