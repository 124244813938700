import React from "react";
import { useEffect, useState } from "react";
import logo from "./zmedia-header-logo.png";
import { useAuthenticator } from "@aws-amplify/ui-react";
import styles from "./Footer.module.css"; // Changed import to Footer.module.css

function Footer() {
  // Changed component name to Footer
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (authStatus === "authenticated") {
      setIsAuthenticated(true);
    }
  }, [authStatus]);

  return (
    <>
      <div className={styles.footerCustom}>
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 ">
          <div className="col-md-4 d-flex align-items-center">
            <a
              href="/"
              className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1"
            ></a>
            <span className={`mb-3 mb-md-0 text-muted ${styles.footerHeader}`}>
              &copy; 2024 Z-Media. All rights reserved
            </span>
          </div>

          <ul className="nav col-md-4 justify-content-end list-unstyled d-flex"></ul>
        </footer>
      </div>
    </>
  );
}

export default Footer;
{
  /* Exporting Footer component */
}
