import React, { useState, useContext, useEffect } from "react";
import NavBar from "../../navbar/NavBar-Archive";
import config from "../../../amplifyconfiguration.json";

import styles from "./Dashboard.module.css"; // Assuming you have a CSS file for styling
import ProjectTable from "../../ProjectTable/ProjectTable";
import OverviewTable from "../../OverviewTable/OverviewTable";
import { Button, withAuthenticator } from "@aws-amplify/ui-react";
import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import Account from "../Account/Account";
import Payment from "./Payment";
import ReactGA from "react-ga4";
Amplify.configure(config);

function Dashboard() {
  
  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.send({ hitType: "pageview", page: "/dashboard", title: "dashboard" });
  }
  , []);
  return (
    <Authenticator>
      <div className="">
        <div className="d-flex max-width flex-column  ">
          <div className={`${styles.dashboardTable} align-items-center`}>
            <ProjectTable />
          </div>
        </div>
      </div>
    </Authenticator>
  );
}

// default props
Dashboard.defaultProps = {
  isMinimized: false,
};

export default withAuthenticator(Dashboard);
