import { React, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "@aws-amplify/ui-react";
import PricingBox from "./PricingBox/PricingBox";
import ReactGA from "react-ga4";
function DIYPricing() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/pricing", title: "Pricing" });
  }, []);
  return (
    <div
      style={{ paddingTop: "50px", minHeight: "50vh" }}
      className="d-flex justify-items-center align-items-center"
    >
      <PricingBox />
    </div>
  );
}

export default DIYPricing;
