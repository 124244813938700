import React, { useEffect, useState } from "react";
import NavBar from "../../navbar/NavBar-Archive";
import styles from "./Account.module.css"; // Assuming you have a CSS file for styling
import ProjectTable from "../../ProjectTable/ProjectTable";
import OverviewTable from "../../OverviewTable/OverviewTable";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { getUserData } from "../../../api/user";
import { ConsoleLogger } from "aws-amplify/utils";
import "./index.css";
import ReactGA from "react-ga4";
function Account() {
  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.send({ hitType: "pageview", page: "/account", title: "account" });
  }, []);

  // Send GET request to the server to get the account data
  const [accountData, setAccountData] = useState({
    title: "Account Information",
    textData: [
      {
        text: "Username",
        subtext: "",
      },
      {
        text: "Name",
        subtext: "",
      },
      {
        text: "Email Address",
        subtext: "",
      },
    ],
  });

  const createUserData = async (username, name, email) => {
    let data = {
      username: username,
      name: name,
      email: email,
    };
    return data;
  };

  // useEffect to getUserInfo from the server
  useEffect(() => {
    const getUserInfo = async () => {
      const response = await getUserData()
        .then((data) => {
          createUserData(
            data.Item.preferred_username,
            data.Item.fullname,
            data.Item.email
          ).then((data) => {
            setAccountData(data);
          });
          return data;
        })
        .catch((err) => {});
    };

    getUserInfo();
    // setAccountData(createUserData(userData.username,userData.name,userData.email));
  }, []);

  return (
    <div
      style={{ paddingTop: "50px", minHeight: "50vh" }}
      className="d-flex justify-items-center align-items-center"
    >
      <div className={` container  py-3`}>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <div className={`card h-100 ${styles.card}`}>
              {" "}
              <div className={styles["account-settings"]}>
                <div
                  className={
                    styles["user-profile"] +
                    " d-flex flex-column  align-items-center"
                  }
                >
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className={`bi bi-person ${styles["avatar-img"]}`}
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                    </svg>
                  </div>
                  <h5
                    className={
                      styles["user-name"] + " fs-3 alegreya-sans-sc-regular"
                    }
                  >
                    {accountData.username}
                  </h5>
                  <h6
                    className={
                      styles["user-email"] + " alegreya-sans-sc-regular"
                    }
                  >
                    {accountData.email}
                  </h6>
                </div>
                {accountData.about ? (
                  <div className={styles.about}>
                    <h5 className={styles["about-h5"]}>About</h5>
                    <p className={styles["about-p"]}>{accountData.about}</p>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
            <div className={`card h-100 ${styles.card}`}>
              <div className="card-body">
                <div className="row gutters">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <h6
                      className={`mb-2  ${styles["about-h5"]} alegreya-sans-sc-regular`}
                    >
                      Personal Details
                    </h6>{" "}
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group alegreya-sans-sc-regular">
                      <label htmlFor="fullName">Full Name</label>
                      <input
                        type="text"
                        className={`form-control ${styles["form-control"]}`}
                        id="fullName"
                        placeholder={accountData.name}
                        value={accountData.name || ""} // if accountData.name is undefined, use an empty string // assuming accountData.about is the value you want to display
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group alegreya-sans-sc-regular">
                      <label htmlFor="eMail">Email</label>
                      <input
                        type="email"
                        className={`form-control ${styles["form-control"]}`}
                        id="eMail"
                        placeholder=""
                        value={accountData.email || ""} // assuming accountData.about is the value you want to display
                        disabled
                      />
                    </div>
                  </div>

                  {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="phone">Phone</label>
                      <input
                        type="text"
                        className={`form-control ${styles["form-control"]}`}
                        id="phone"
                        placeholder="Enter phone number"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="website">Website URL</label>
                      <input
                        type="url"
                        className={`form-control ${styles["form-control"]}`}
                        id="website"
                        placeholder="Website url"
                      />
                    </div>
                  </div> */}
                </div>

                {/* 
                <div className="row gutters">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <h6 className={`mt-3 mb-2  ${styles["about-h5"]}`}>
                      Address
                    </h6>{" "}
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="Street">Street</label>
                      <input
                        type="name"
                        className={`form-control ${styles["form-control"]}`}
                        id="Street"
                        placeholder="Enter Street"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="ciTy">City</label>
                      <input
                        type="name"
                        className={`form-control ${styles["form-control"]}`}
                        id="ciTy"
                        placeholder="Enter City"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="sTate">State</label>
                      <input
                        type="text"
                        className={`form-control ${styles["form-control"]}`}
                        id="sTate"
                        placeholder="Enter State"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="zIp">Zip Code</label>
                      <input
                        type="text"
                        className={`form-control ${styles["form-control"]}`}
                        id="zIp"
                        placeholder="Zip Code"
                      />
                    </div>
                  </div>
                </div>

                <div className="row gutters">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <h6 className={`mt-3 mb-2 ${styles["about-h5"]}`}>
                      Social Media Profiles
                    </h6>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="Facebook">Facebook</label>
                      <input
                        type="text"
                        className={`form-control ${styles["form-control"]}`}
                        id="Facebook"
                        placeholder="Enter Facebook URL"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="Twitter">Twitter</label>
                      <input
                        type="text"
                        className={`form-control ${styles["form-control"]}`}
                        id="Twitter"
                        placeholder="Enter Twitter Handle"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="Instagram">Instagram</label>
                      <input
                        type="text"
                        className={`form-control ${styles["form-control"]}`}
                        id="Instagram"
                        placeholder="Enter Instagram Username"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label htmlFor="LinkedIn">LinkedIn</label>
                      <input
                        type="text"
                        className={`form-control ${styles["form-control"]}`}
                        id="LinkedIn"
                        placeholder="Enter LinkedIn Profile URL"
                      />
                    </div>
                  </div>
                </div>

                <div className="row gutters">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <h6 className={`mt-3 mb-2 ${styles["about-h5"]}`}>
                      About Information
                    </h6>
                    <div className="form-group">
                      <label htmlFor="AboutOverview">Overview</label>
                      <textarea
                        className={`form-control ${styles["form-control"]}`}
                        id="AboutOverview"
                        placeholder="Write a brief overview about yourself or your business"
                        rows="5"
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="row gutters">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                    <div
                      className={`d-flex justify-content-end text-right ${styles.buttonContainer}`}
                    >
                      {" "}
                      <button
                        type="button"
                        id="submit"
                        name="submit"
                        className="btn btn-secondary m-1"
                        style={{ minWidth: "100px" }}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        id="submit"
                        name="submit"
                        style={{ minWidth: "100px" }}
                        className={` btn btn-primary  m-1 ${styles.button} `}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withAuthenticator(Account);
