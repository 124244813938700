import { BsLink45Deg, BsBrush, BsLock, BsUnlock, BsTrash3 } from 'react-icons/bs';

export const IconView = () => {
    return <BsLink45Deg style={{ pointerEvents: "none", zIndex: 0 ,fill:"black"}} />;
};

export const IconEdit = () => {
    return <BsBrush style={{ pointerEvents: "none", zIndex: 0 ,fill:"black"}} />;
};

export const IconLock = () => {
    return <BsLock style={{ pointerEvents: "none", zIndex: 0 ,fill:"black"}} />;
};

export const IconUnlock = () => {
    return <BsUnlock style={{ pointerEvents: "none", zIndex: 0 ,fill:"black"}} />;
};

export const IconDelete = () => {
    return <BsTrash3 style={{ pointerEvents: "none", zIndex: 0 ,fill:"black"}} />;
};