import React from "react";
import styles from "./AboutUsSection.module.css";
const AboutUsSection = () => {
  return (
    <div className="container">
      <div className="row featurette">
        <div className="col-md-7 d-flex flex-column justify-items-center align-items-center abhaya-libre-regular  ">
          <h2 className={`${styles.featuretteHeading} fw-normal lh-1 display-3 abhaya-libre-regular`}>
            {" "}
            Who are are?{" "}
            <span className="text-muted">The story of our beginnings.</span>
          </h2>
          <p className=" fs-5">
            At Z-Media, our journey began not from a business plan, but from a
            personal experience — our own engagement photo delivery via a Google
            Drive link. This mundane delivery method sparked a realization: the
            need for a more intimate, personalized photo-sharing platform.
          </p>
          <p className="fs-5">
            Developing a gallery enhanced with AI facial recognition, we
            transformed how friends and family relive shared memories. The
            positive feedback was overwhelming, confirming a gap in the market.
          </p>
          <p className="fs-5">
            Z-Media now empowers photographers to offer their clients this
            enriched experience, ensuring every gallery delivered is as unique
            and cherished as the moments it captures.
          </p>
        </div>
        <div className="col-md-5 justify-items-center align-items-center pt-5 pt-md-0">
          <img 
            src="/assets/images/aboutus1.jpg"
            alt="About Us"
          />
        </div>
      </div>

    
    </div>
  );
};

export default AboutUsSection;
