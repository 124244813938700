import React, { useState, useRef, useEffect } from "react";
import ReactGA from "react-ga4";
import styles from "./ContactPage.module.css";
import emailjs from "@emailjs/browser";
import { useBreakpointValue } from "@chakra-ui/react";

function ContactPage({ DIY = false }) {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/contact", title: "contact" });
  }, []);

  const emailRef = useRef();
  const nameRef = useRef();
  const messageRef = useRef();
  const successMessage = useRef();
  const failureMessage = useRef();
  useEffect(() => emailjs.init("uMY5O3yQbqfwTdLlc"), []);

  // Set ref for form element
  const submit = useRef(null);
  const submitButton = useRef(null);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const serviceId = "service_5ftgv6d";
    const templateId = "template_uzlv4ha";
    try {
      //console.log(nameRef.current.value);
      //console.log(emailRef.current.value);
      //console.log(messageRef.current.value);

      await emailjs.send(serviceId, templateId, {
        user_name: nameRef.current.value,
        email: emailRef.current.value,
        message: messageRef.current.value,
      });
      successMessage.current.style.display = "block";
    } catch (error) {
      failureMessage.current.style.display = "block";
    } finally {
      // disable the submit button after form submission
      submitButton.current.style.display = "none";
    }
    submitButton.current.setAttribute("disabled", "disabled");
  };
  return (
    <>
      <div
        className="d-flex flex-column justify-content-center align-items-center "
        style={{ padding: useBreakpointValue({ base: "30px", lg: "50px" }) }}
      >
        <span
          style={{ color: "#00057B" }}
          className="fs-6 alegreya-sans-sc-bold"
        >
          {DIY === true ? "Ready to get started?" : "Got a Question?"}
        </span>
        <h1 className="abhaya-libre-regular display-6 ">Contact Z-Media</h1>
        <p className="abhaya-libre-regular text-center">
          {DIY === true
            ? "We're here to help you get started! Please tell us about your event and photo count!"
            : "We're here to help and answer any question you might have. We look forward to hearing from you!"}
        </p>

        <div className="col-10 col-md-6">
          <form
            key=""
            ref={submit}
            onSubmit={handleSubmit}
            className="d-flex flex-column gap-3 "
            style={{
              width: "100%", // Ensure the form takes up all the horizontal spac
              border: "#D9D9D9 1px solid",
              borderRadius: "8px",
              padding: "24px",
            }}
          >
            <div className="">
              <label htmlFor="" className={styles["col-form-label"]}>
                {" "}
                Name *
              </label>
              <input
                type="text"
                ref={nameRef}
                className={`${styles.formControl} form-control`}
                name="name"
                id="name"
                placeholder="Your name"
                required
              />
            </div>
            <div className="">
              <label htmlFor="" className={styles["col-form-label"]}>
                Email *
              </label>
              <input
                type="text"
                ref={emailRef}
                className={styles.formControl + " form-control"}
                name="email"
                id="email"
                placeholder="Your email"
                required
              />
            </div>

            <div className="">
              <div className="">
                <label htmlFor="message" className={styles["col-form-label"]}>
                  Message *
                </label>
                <textarea
                  className={styles.formControl + " form-control"}
                  name="message"
                  ref={messageRef}
                  id="message"
                  cols="30"
                  rows="4"
                  placeholder={
                    DIY == true
                      ? "Please tell us about your event and approximate photo count!"
                      : "Write your message"
                  }
                  required
                ></textarea>
              </div>
            </div>
            <div className="">
              <div className=" form-group">
                <input
                  ref={submitButton}
                  type="submit"
                  style={{ width: "100%" }}
                  value="Send Message"
                  className={`btn btn-primasry rounded-0 py-2 px-4 ${styles["submitButton"]}`}
                />
                <span className="submitting"></span>
              </div>
            </div>
          </form>

          <div
            ref={failureMessage}
            id="form-message-warning mt-4"
            style={{ textAlign: "center", display: "none" }}
            className={styles.failureMessage}
          >
            <p>
              Error, Please refresh the page and try again.
              <br />
              If the problem persists, please contact us at
              zachary.chirayil@gmail.com.
            </p>{" "}
          </div>
          <div
            ref={successMessage}
            id="form-message-success"
            style={{ textAlign: "center", display: "none" }}
            className={styles.successMessage}
          >
            Your message was sent, thank you!
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactPage;
