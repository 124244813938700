import React, { useState, useEffect, useRef, useCallback } from "react";
import GallerySection from "./GallerySection";
import InfiniteScroll from "react-infinite-scroll-component";
import { list } from "aws-amplify/storage";
import { findMatchingFaces } from "../../api/ai";
import GalleryMessage from "./CompleteMessage/GalleryMessage";
import lgThumbnail from "lightgallery/plugins/thumbnail";
// import LightGallerySection from "./LightGallerySection/LightGallerySection";
import LightGallery from "lightgallery/react";
import { getUrl } from "aws-amplify/storage";
import { Loader } from "@aws-amplify/ui-react";

const InfiniteScrollExample = ({
  isFullGalleryActive: propisFullGalleryActive,
  uploadedSelfieImage,
  ClientData,
  setFaceRecognitionHasMoreImages,
}) => {
  /****************************************************************************** */
  // VARIABLES
  /****************************************************************************** */
  // Client Data
  const project_name = ClientData.project_info.PROJECT_NAME;
  const targetIdentityId = ClientData.project_info.IDENTITY_ID;
  const accessLevel = ClientData.project_info.PROJECT_STATUS;
  const prefix_originalImages = project_name + "/originalImages/";
  const prefix_resizedImages = project_name + "/resizedImages/";
  const prefix_highQualityImages = project_name + "/highQualityImages/";
  const isLoadingNextPage = useRef(false);
  // Gallery Variables
  let PAGE_SIZE = 15;
  let PAGE_SIZE_SELFIE = 30;

  // Selfie image
  const imageChanged = useRef(false);
  const currentImage = useRef(null);
  const [faceRecognitionData, setFaceRecognitionData] = useState({
    imageList: [],
    next: () => {
      loadNextFaceMatches();
    },
    hasMore: true,
    counterRef: 0,
    loading: <Loader className="p-2" filledColor={"gray"} variation="linear" />,

    endMessage: <GalleryMessage message="Complete" />,
  });
  const faceRecognitionDataRef = useRef(faceRecognitionData);

  // Full Gallery
  const nextToken = useRef("start");
  const [fullGalleryData, setFullGalleryData] = useState({
    imageList: [],
    lightboxUrls: [],
    next: () => {
      loadNextPageUniversal(
        fullGalleryData,
        setFullGalleryData,
        fullGalleryDataRef,
        dynamicEl
      );
    },
    hasMore: true,
    counterRef: 0,
    loading: <GalleryMessage message="Loading..." />,
    endMessage: <GalleryMessage message="Complete" />,
  });
  const fullGalleryDataRef = useRef(fullGalleryData);

  // LightGallery
  let [dynamicEl] = useState([]);
  let [dynamicElFaceRec] = useState([]);
  const [selectedLightGalleryImage, setSelectedLightGalleryImage] =
    useState(null);
  const onInit = useCallback((detail) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);
  const onAfterCloseCurrentIndex = useRef(0);
  const lightGallery = useRef(null);
  const onBeforeSlideCurrentIndex = useRef(0);

  /****************************************************************************** */
  // USE EFFECTS
  /****************************************************************************** */

  // Use Effects - General
  const propisFullGalleryActiveRef = useRef(propisFullGalleryActive);
  useEffect(() => {
    // Used for gallery designer only
    if (accessLevel === "private") {
      if (fullGalleryData.imageList.length === 0 && propisFullGalleryActive) {
        fullGalleryData.next();
      }
    }
    propisFullGalleryActiveRef.current = propisFullGalleryActive;

    // If no images are loaded then load the first page
    if (fullGalleryData.imageList.length === 0) {
      loadNextPageUniversal(
        fullGalleryData,
        setFullGalleryData,
        fullGalleryDataRef,
        dynamicEl
      );
      
    }
  }, [propisFullGalleryActive]);

  useEffect(() => {
    if (!selectedLightGalleryImage) {
      return;
    }
    let refVaraible = "";

    if (propisFullGalleryActive === true) {
      refVaraible = fullGalleryDataRef;
    } else {
      refVaraible = faceRecognitionDataRef;
    }

    // Find the index of the selected image
    const index = refVaraible.current.imageList.findIndex(
      (element) =>
        element.key
          .replace(prefix_originalImages, "")
          .replace(prefix_resizedImages, "") ===
        selectedLightGalleryImage.key.replace(prefix_resizedImages, "")
    );

    if (index === -1) {
      console.error("ERROR: No index found");
      return;
    }

    lightGallery.current.openGallery(index);
  }, [selectedLightGalleryImage]);

  // useEffects - Selfie

  useEffect(() => {
    // Reset everything when the selfie image changes
    if (!uploadedSelfieImage) {
      return;
    }

    // Reset the counter when the selfie image changes and set imagelist to empty
    imageChanged.current = true;

    setFaceRecognitionData((preFaceRecognitionData) => ({
      ...preFaceRecognitionData,
      imageList: [],
      counterRef: 0,
      hasMore: true,
    }));

    faceRecognitionDataRef.current.imageList = [];
    faceRecognitionDataRef.current.counterRef = 0;
    faceRecognitionDataRef.current.hasMore = true;
    dynamicElFaceRec.length = 0;

    currentImage.current = uploadedSelfieImage;
  }, [uploadedSelfieImage]);

  useEffect(() => {
    if (imageChanged.current) {
      loadNextFaceMatches();
      setFaceRecognitionHasMoreImages(true);

      imageChanged.current = false;
      return;
    }
  }, [faceRecognitionData]);

  /****************************************************************************** */
  // FUNCTIONS
  /****************************************************************************** */

  const loadNextFaceMatches = async () => {
    // If not selfie image is uploaded then return
    if (!currentImage.current) {
      return;
    }

    if (faceRecognitionDataRef.current.hasMore === false) {
      return;
    }

    let counterRef = faceRecognitionData.counterRef;
    let endCounter = faceRecognitionData.counterRef + PAGE_SIZE_SELFIE;

    faceRecognitionDataRef.current.counterRef = endCounter;

    // Get the face matches by calling the findMatchingFaces function
    const matched_faces = await findMatchingFaces(
      currentImage.current,
      counterRef,
      endCounter,
      project_name
    );

    // If no face matches are found, log an error and return
    if (!matched_faces) {
      console.error("ERROR: No face matches found");
      return;
    }

    // Add the matched faces to the imageList
    matched_faces.presigned_url.forEach((url) => {
      faceRecognitionDataRef.current.imageList.push({ key: url.object_key });
    });

    const lgImages = await Promise.all(
      matched_faces.presigned_url.map(async (element) => {
        let originalKey = element.object_key;
        let resizedKey = originalKey.replace(
          prefix_originalImages,
          prefix_resizedImages
        );

        let highqualityKey = originalKey.replace(
          prefix_resizedImages,
          prefix_highQualityImages
        );
        const highQuailtyURL = await getUrl({
          key: highqualityKey,
          options: {
            accessLevel: accessLevel,
            targetIdentityId: targetIdentityId,
          },
        });
        const ResizedUrl = await getUrl({
          key: resizedKey,
          options: {
            accessLevel: accessLevel,
            targetIdentityId: targetIdentityId,
          },
        });

        return {
          key: highqualityKey,
          src: highQuailtyURL.url.href,
          thumb: ResizedUrl.url.href,
        };
      })
    );

    dynamicElFaceRec.push(...lgImages);

    // dynamicElFaceRec.push(
    //   ...matched_faces.presigned_url.map((element) => {
    //     return {
    //       key: element.object_key,
    //       src: element.object_url,
    //       thumb: element.object_url,
    //     };
    //   })
    // );

    // If no face matches are found, set hasMore to false
    if (
      matched_faces.total_face_matches === -1 ||
      matched_faces.total_face_matches === 0 ||
      matched_faces.total_face_matches < PAGE_SIZE_SELFIE
    ) {
      console.log("No face matches found ");
      faceRecognitionDataRef.current.hasMore = false;
    }

    if (lightGallery.current.lgOpened === false) {
      setFaceRecognitionData((preFaceRecognitionData) => ({
        ...preFaceRecognitionData,
        imageList: [...faceRecognitionDataRef.current.imageList],
        counterRef: faceRecognitionDataRef.current.counterRef,
        hasMore: faceRecognitionDataRef.current.hasMore,
      }));
    }
  };

  const loadNextPageUniversal = async (
    galleryData,
    setGalleryData,
    galleryDataRef,
    dynamic
  ) => {
    if (!galleryData || galleryDataRef.current.hasMore === false) {
      return;
    }

    isLoadingNextPage.current = true;

    // If the nextToken is "start" then set it to undefined to start the process.
    // If this is not there then it will not load the first page
    if (nextToken.current === "start") {
      nextToken.current = undefined;
    }

    // Get original images list
    let response = await list({
      prefix: prefix_originalImages,
      options: {
        pageSize: PAGE_SIZE,
        nextToken: nextToken.current,
        accessLevel: accessLevel,
        targetIdentityId: targetIdentityId,
      },
    });

    // Set the nextToken for the next page
    if (response.nextToken) {
      nextToken.current = response.nextToken;
    } else {
      nextToken.current = undefined;
    }

    if (nextToken.current === undefined) {
      galleryDataRef.current.hasMore = false;
    }
    response.items = response.items.filter((item) => item.size > 0);
    const imageList = response.items;

    const lgImages = await Promise.all(
      imageList.map(async (element) => {
        let originalKey = element.key;

        let resizedKey = originalKey.replace(
          prefix_originalImages,
          prefix_resizedImages
        );

        let highqualityKey = originalKey.replace(
          prefix_originalImages,
          prefix_highQualityImages
        );
        const highQuailtyURL = await getUrl({
          key: highqualityKey,
          options: {
            accessLevel: accessLevel,
            targetIdentityId: targetIdentityId,
          },
        });
        const ResizedUrl = await getUrl({
          key: resizedKey,
          options: {
            accessLevel: accessLevel,
            targetIdentityId: targetIdentityId,
          },
        });

        return {
          key: highqualityKey,
          src: highQuailtyURL.url.href,
          thumb: ResizedUrl.url.href,
        };
      })
    );

    galleryDataRef.current.imageList.push(...response.items);
    dynamic.push(...lgImages);

    // Scenario 2: Light gallery is NOT open and Images need to be loaded
    if (lightGallery.current.lgOpened === false) {
      setGalleryData((prevFullGalleryData) => ({
        ...prevFullGalleryData,
        imageList: [...galleryDataRef.current.imageList],
        hasMore: galleryDataRef.current.hasMore,
      }));
    }
  };

  ///////////////////////////////////////////////////////////

  const onBeforeSlide = async (detail) => {
    const { index } = detail;
    if (index === onBeforeSlideCurrentIndex.current) {
      return;
    }

    onBeforeSlideCurrentIndex.current = index;

    if (
      index === lightGallery.current.galleryItems.length - 1 ||
      index === lightGallery.current.galleryItems.length - 2 ||
      index === lightGallery.current.galleryItems.length - 3
    ) {
      if (propisFullGalleryActiveRef.current === true) {
        if (fullGalleryDataRef.current.hasMore === false) {
          return;
        }

        await loadNextPageUniversal(
          fullGalleryData,
          setFullGalleryData,
          fullGalleryDataRef,
          dynamicEl
        );
        lightGallery.current.updateSlides(
          dynamicEl,
          lightGallery.current.index
        );
      } else {
        if (faceRecognitionDataRef.current.hasMore === false) {
          return;
        }
        await loadNextFaceMatches();
        lightGallery.current.updateSlides(
          dynamicElFaceRec,
          lightGallery.current.index
        );
      }
    }
    return;
  };

  const onAfterClose = (detail) => {
    document.body.style.overflow = "auto";
    const { index } = detail.instance;

    if (index === onAfterCloseCurrentIndex.current) {
      return;
    }

    onAfterCloseCurrentIndex.current = index;
    lightGallery.current.lgOpened = false;

    if (propisFullGalleryActiveRef.current === true) {
      setFullGalleryData((prevFullGalleryData) => ({
        ...prevFullGalleryData,
        imageList: [...fullGalleryDataRef.current.imageList],
        hasMore: faceRecognitionDataRef.current.hasMore,
      }));
    } else {
      if (faceRecognitionDataRef.current.hasMore === false) {
        setFaceRecognitionData((prevFaceRecognitionData) => ({
          ...prevFaceRecognitionData,
          imageList: [...faceRecognitionDataRef.current.imageList],
          hasMore: faceRecognitionDataRef.current.hasMore,
        }));
      } else {
        loadNextFaceMatches();
      }
    }

    return;
  };

  return (
    <>
      <div>
        <LightGallery
          mode="lg-fade"
          onInit={onInit}
          speed={500}
          plugins={[lgThumbnail]}
          elementclassNameNames="image-container"
          dynamic
          dynamicEl={propisFullGalleryActive ? dynamicEl : dynamicElFaceRec}
          download={true}
          licenseKey="82ADECBE-6998-4C83-B314-630C871F80BB"
          onAfterOpen={() => {
            lightGallery.current.lgOpened = true;
          }}
          onAfterClose={onAfterClose}
          onBeforeSlide={onBeforeSlide}
          onBeforeOpen={() => {
            document.body.style.overflow = "hidden";
          }}
          mobileSettings={{
            controls: false, // Hide controls on mobile
            showCloseIcon: false, // Hide close icon on mobile
            download: false, // Disable download on mobile
          }} // Pass mobileSettings
        ></LightGallery>{" "}
        <InfiniteScroll
          dataLength={
            propisFullGalleryActive
              ? fullGalleryData.imageList.length
              : faceRecognitionData.imageList.length
          }
          next={
            propisFullGalleryActive
              ? fullGalleryData.next
              : faceRecognitionData.next
          }
          hasMore={
            propisFullGalleryActive
              ? fullGalleryData.hasMore
              : faceRecognitionData.hasMore
          }
          loader={
            propisFullGalleryActive
              ? fullGalleryData.loading
              : uploadedSelfieImage
              ? faceRecognitionData.loading
              : null
          }
          endMessage={
            propisFullGalleryActive
              ? fullGalleryData.endMessage
              : faceRecognitionData.endMessage
          }
          scrollThreshold={0.25}
        >
          <div style={{ display: propisFullGalleryActive ? "block" : "none" }}>
            <GallerySection
              imageList={[...fullGalleryData.imageList]}
              ClientData={ClientData}
              setSelectedLightGalleryImage={setSelectedLightGalleryImage}
            />
          </div>

          <div style={{ display: propisFullGalleryActive ? "none" : "block" }}>
            <GallerySection
              imageList={[...faceRecognitionData.imageList]}
              ClientData={ClientData}
              setSelectedLightGalleryImage={setSelectedLightGalleryImage}
            />
          </div>
        </InfiniteScroll>
      </div>
    </>
  );
};

export default InfiniteScrollExample;
