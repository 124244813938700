import React from "react";

import { ChatEngine } from "react-chat-engine";
import { Authenticator } from "@aws-amplify/ui-react";

const SupportAdmin = () => {
  return (
    <Authenticator>
      {({ signOut, user }) => (
        <>
          <ChatEngine
            projectID={process.env.REACT_APP_CE_PROJECT_ID}
            userName={"Zach"}
            userSecret={user.username}
            height="calc(100vh - 12px)"
          />
        </>
      )}
    </Authenticator>
  );
};

export default SupportAdmin;
