
export const findMatchingFaces = async function (selfieImageName,start_range,end_range,project_name) {
    const apiGateway =
      process.env.REACT_APP_ZMEDIA_API_GATEWAY;
    const requestUrl = `${apiGateway}/api/ai` + new URLSearchParams({});

    return await fetch(requestUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        objectKey: selfieImageName,
        start_range: start_range,
        end_range: end_range,
        project_name: project_name,
        
      }),
    })
      .then((data) => {
        return data.json();
      })
      .catch((error) => {
        console.error(error);
      });
  };