import { ReactNode } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import logo from "./zmedia-logo.svg";

import "./NavBar.css";
import {
  Box,
  Text,
  useBreakpointValue,
  Flex,
  Avatar,
  HStack,
  Link,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { Container } from "react-bootstrap";

const NavLink = ({ children, href, fontColor, hoverColor, onClick }) => {
  const location = useLocation();
  const textColor = fontColor ? fontColor : "black";
  const hoverTextColor = hoverColor ? hoverColor : "rgba(0, 0, 0, .2)";
  return (
    <Link
      px={2}
      py={1}
      onClick={onClick}
      // rounded={"md"}
      color={textColor}
      fontWeight={400}
      fontSize={"12px"}
      letterSpacing={"2px"}
      fontFamily={"Poppins"}
      _hover={{
        textDecoration: "none",
        // bg: useColorModeValue("gray.200", "gray.700"),
        fontWeight: "600",
        color: hoverTextColor,
      }}
      href={href}
      className={
        location.pathname === href ? "navLinkActive roboto-bold" : "roboto-bold"
      }
    >
      {children}
    </Link>
  );
};

export default function Simple({ backgroundColor, fontColor, hoverColor }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (authStatus === "authenticated") {
      setIsAuthenticated(true);
    }
  }, [authStatus]);

  async function handleSignOut() {
    try {
      await signOut();
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    } catch (error) {
      //console.log("error signing out: ", error);
    }
  }
  const NavList = (location) => {
    const activeStyle = {
      // Add your hover styles here
      color: "blue", // Example hover style
      textDecoration: "underline", // Example hover style
    };

    return (
      <HStack as={"nav"} spacing={4} display={{ base: "none", md: "flex" }}>
        <NavLink
          fontColor={fontColor}
          hoverColor={hoverColor}
          location={location}
          href="/"
        >
          {"HOME"}
        </NavLink>{" "}
        <NavLink
          fontColor={fontColor}
          hoverColor={hoverColor}
          location={location}
          href="/contact"
        >
          {"CONTACT"}
        </NavLink>
        <NavLink
          fontColor={fontColor}
          hoverColor={hoverColor}
          location={location}
          href="/pricing"
        >
          {"PRICING"}
        </NavLink>
        <NavLink
          fontColor={fontColor}
          hoverColor={hoverColor}
          location={location}
          href="/about"
          break
        >
          {"ABOUT"}
        </NavLink>
        {isAuthenticated && (
          <NavLink
            fontColor={fontColor}
            hoverColor={hoverColor}
            location={location}
            href="/dashboard"
          >
            {"DASHBOARD"}
          </NavLink>
        )}
        {isAuthenticated && (
          <NavLink
            fontColor={fontColor}
            hoverColor={hoverColor}
            location={location}
            href="/account"
          >
            {"ACCOUNT"}
          </NavLink>
        )}
      </HStack>
    );
  };
  const NavListHamburger = (location) => {
    return (
      <Stack
        as={"nav"}
        spacing={4}
        display={{ base: "flex", md: "none" }}
        textAlign={"center"}
      >
        <NavLink
          fontColor={fontColor}
          hoverColor={hoverColor}
          location={location}
          href="/"
        >
          {"HOME"}
        </NavLink>{" "}
        <NavLink
          fontColor={fontColor}
          hoverColor={hoverColor}
          location={location}
          href="/contact"
        >
          {"CONTACT"}
        </NavLink>
        <NavLink
          fontColor={fontColor}
          hoverColor={hoverColor}
          location={location}
          href="/pricing"
        >
          {"PRICING"}
        </NavLink>
        <NavLink
          fontColor={fontColor}
          hoverColor={hoverColor}
          location={location}
          href="/about"
          break
        >
          {"ABOUT"}
        </NavLink>
        {isAuthenticated && (
          <NavLink
            fontColor={fontColor}
            hoverColor={hoverColor}
            location={location}
            href="/dashboard"
          >
            {"DASHBOARD"}
          </NavLink>
        )}
        {isAuthenticated && (
          <NavLink
            fontColor={fontColor}
            hoverColor={hoverColor}
            location={location}
            href="/account"
          >
            {"ACCOUNT"}
          </NavLink>
        )}
      </Stack>
    );
  };

  return (
    <>
      <Box px={4} backgroundColor={backgroundColor ? backgroundColor : "white"}>
        <Container maxW={"7xl"}>
          <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
            {/* <Box width="100px" display={{ base: "flex", md: "none" }}>
              <img src={logo} alt="ZMedia Logo" />
            </Box> */}
            <IconButton
              size={"md"}
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label={"Open Menu"}
              display={{ md: "none" }}
              onClick={isOpen ? onClose : onOpen}
            />
            <div className="d-flex justify-centent-center align-items-center">
              <div className="d-flex d-md-none">
                {/* {!isAuthenticated && <NavLink href="/login">{"Login"}</NavLink>} */}
                {isAuthenticated && (
                  <NavLink
                    fontColor={fontColor}
                    hoverColor={hoverColor}
                    onClick={handleSignOut}
                  >
                    {"LOGOUT"}
                  </NavLink>
                )}{" "}
              </div>

              {!isAuthenticated && (
                <Button
                  variant={"link"}
                  as={"a"}
                  display={{ base: "inline-flex", md: "none" }}
                  fontSize={"sm"}
                  fontWeight={600}
                  color={"white"}
                  bg={"blue.400"}
                  href={"/contact"}
                  padding={"14px"}
                  height={"40px"}
                  // margin={"14px"}
                  marginTop={"1px"}
                  marginRight={"14px"}
                  _hover={{
                    bg: "blue.300",
                    color: "black",
                  }}
                >
                  Get Started!
                </Button>
              )}
            </div>

            <HStack
              spacing={8}
              alignItems={"center"}
              justify={"left"}
              display={{ base: "none", md: "flex" }}
            >
              <Box width="100px">
                <img src={logo} alt="ZMedia Logo" />
              </Box>
            </HStack>

            <NavList />

            <HStack
              as={"nav"}
              spacing={0}
              display={{ base: "none", md: "flex" }}
            >
              {/* {!isAuthenticated && <NavLink href="/login">{"Login"}</NavLink>} */}
              {isAuthenticated && (
                <NavLink
                  fontColor={fontColor}
                  hoverColor={hoverColor}
                  onClick={handleSignOut}
                >
                  {"LOGOUT"}
                </NavLink>
              )}
              {!isAuthenticated && (
                <Button
                  variant={"link"}
                  as={"a"}
                  display={{ base: "none", md: "inline-flex" }}
                  fontSize={"sm"}
                  fontWeight={600}
                  color={"white"}
                  bg={"blue.400"}
                  href={"/contact"}
                  padding={"14px"}
                  margin={"14px"}
                  _hover={{
                    bg: "blue.300",
                    color: "black",
                  }}
                >
                  Get Started!
                </Button>
              )}
            </HStack>

          </Flex>

          {isOpen ? (
            <Box pb={4}>
              <Stack as={"nav"} spacing={4}>
                <NavListHamburger />
              </Stack>
            </Box>
          ) : null}
        </Container>
      </Box>
    </>
  );
}
