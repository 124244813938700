import { Outlet } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import NavBar from "../components/navbar/NavBar";
import SupportEngine from "../components/SupportEngine";
const Layout = () => {
  return (
    <>
      <div className="root-layout-content">
        <NavBar />
        <div className="root-layout-container">
          {/* <SupportEngine /> */}
          <div className="max-width root-layout-outlet">
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
