import { getIdentityId } from "./user";

import { getToken } from "./auth";

// Store contant values
export const FREE_SIZE_THRESHOLD = 10 * 1024 * 1024 * 1024;
export const BASIC_SIZE_THRESHOLD = 25 * 1024 * 1024 * 1024;
export const PRO_SIZE_THRESHOLD = 50 * 1024 * 1024 * 1024;

export const getStripeGalleryPayment = async function (
  project_name,
  product_id,
  project_size
) {
  //console.log(`${bucket} start_range-${start_range}`)
  //console.log(`end_range-${end_range}`)
  const token = await getToken();
  const identity_id = await getIdentityId();
  const apiGateway = process.env.REACT_APP_ZMEDIA_API_GATEWAY;
  const requestUrl = `${apiGateway}/api/payment`;
  return await fetch(requestUrl, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify({
      project_name: project_name,
      product_id: product_id,
      project_size: project_size,
      customer_data: {
        identity_id: identity_id,
        //     stateMachineArn:
        //       "arn:aws:states:us-east-1:176304364130:stateMachine:MyStateMachine-skpvohbhy",
      },
    }),
  })
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      console.error("error" + error);
    });
};
