import { getToken } from "./auth";


export const getTheme = async function (theme_name) {
    const token = await getToken();
    const apiGateway =
      process.env.REACT_APP_ZMEDIA_API_GATEWAY;
    const requestUrl =
      `${apiGateway}/api/theme?` +
      new URLSearchParams({
        theme_name:theme_name,
      });
  
    return await fetch(requestUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    })
      .then((data) => {
        return data.json();
      })
      .catch((error) => {
        console.error(error);
    });
  };


  export const getThemeList = async function () {
   setTimeout(() => {
   }, 1000);
   return ["fancy", "fancy1"];

  };


  export const getDefaultThemes = async function () {
    const token = await getToken();
    const apiGateway =
      process.env.REACT_APP_ZMEDIA_API_GATEWAY;
    const requestUrl =
      `${apiGateway}/api/themes`;
  
    return await fetch(requestUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    })
      .then((data) => {
        return data.json();
      })
      .catch((error) => {
        console.error(error);
    });
  };