import { React, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "@aws-amplify/ui-react";
import styles from "./AboutUs.module.css";
import AboutUsSection from "../MarketingPage/AboutUsSection/AboutUsSection";
import ReactGA from "react-ga4";
function AboutUs() {
  useEffect(() => {
    // Initialize Google Analytics
  ReactGA.send({ hitType: "pageview", page: "/about", title: "about" });
  }
  , []);
  return (
    <div
      style={{ paddingTop: "80px", minHeight: "50vh" }}
      className="d-flex justify-items-center align-items-center"
    >
        <AboutUsSection />
    </div>
  );
}

export default AboutUs;
