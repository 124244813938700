import React, { useEffect } from "react";
import config from "../../../amplifyconfiguration.json";
import { useTheme, useAuthenticator } from "@aws-amplify/ui-react";
import { useBreakpointValue } from "@aws-amplify/ui-react";
import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { View, Image, Text, Heading, Button } from "@aws-amplify/ui-react";
import styles from "./signuppage.module.css";

Amplify.configure(config);

function SignUpPage() {
  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.send({ hitType: "pageview", page: "/signup", title: "SignUpPage" });
  }, []);

  const components = {
    // Header() {
    //   const { tokens } = useTheme();

    //   return (
    //     <View textAlign="center" padding={tokens.space.large}>
    //       <Image
    //         alt="Amplify logo"
    //         src="https://docs.amplify.aws/assets/logo-dark.svg"
    //       />
    //     </View>
    //   );
    // },

    Footer() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>
            &copy; All Rights Reserved
          </Text>
        </View>
      );
    },

    SignIn: {
      Header() {
        const { tokens } = useTheme();

        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Sign in to your account
          </Heading>
        );
      },
      Footer() {
        const { toForgotPassword } = useAuthenticator();

        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toForgotPassword}
              size="small"
              variation="link"
            >
              Reset Password
            </Button>
          </View>
        );
      },
    },

    SignUp: {
      Header() {
        const { tokens } = useTheme();

        return <></>;
      },
      Footer() {
        const { toSignIn } = useAuthenticator();

        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toSignIn}
              size="small"
              variation="link"
            >
              Back to Sign In
            </Button>
          </View>
        );
      },
    },
    ConfirmSignUp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
    SetupTotp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
    ConfirmSignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
    ForgotPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
    ConfirmResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
  };

  const navigate = useNavigate();
  const { user } = useAuthenticator((context) => [context.user]);
  useEffect(() => {
    //Runs only on the first render
    //console.log(user);
    if (user) {
      navigate("/dashboard");
    }
  }, [user]);
  const SideBar = () => {
    return (
      <div
        className="bg-prismary d-none  d-lg-block  "
        style={{
          height: "100%",
          maxWidth: "480px",
          paddingRight: useBreakpointValue({ base: "0px", md: "120px" }),
        }}
      >
        <h1
          className={
            styles.heroHeader +
            " display-3  abhaya-libre-regular  text-center  "
          }
        >
          Lets get started!
        </h1>

        <p
          className={styles.heroText + " h4 abhaya-libre-regular text-center "}
        >
          Enhance Your Community's Photo Experience—Get Started Free!
        </p>
      </div>
    );
  };

  return (
    <div className="d-flex flex-column flex-lg-row gap-5 gap-lg-5  justify-content-center  align-items-center p-5">
    
      <div
        className="bg-prismary  d-lg-none  "
        style={{
          height: "100%",
          paddingRight: useBreakpointValue({ base: "0px", md: "120px" }),
        }}
      >
        <h1
          className={
            styles.heroHeaderSmallScreen +
            " display-3  abhaya-libre-regular  text-center  "
          }
        >
          Lets get started!
        </h1>

        <p
          className={styles.heroText + " h4 abhaya-libre-regular text-center "}
        >
          Enhance Your Community's Photo Experience—Get Started Free!
        </p>
      </div>

      <div classname="">
        <Authenticator
          components={components}
          initialState="signUp"
        ></Authenticator>
      </div>
      <SideBar />

    </div>
  );
}

// default props
SignUpPage.defaultProps = {
  isMinimized: false,
};

export default SignUpPage;
