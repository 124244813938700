import React from "react";
import styles from "./Banner.module.css";
import UpArrow from "../../../UpArrow/UpArrow";

function Banner() {
  return (
    <div className={`${styles.banner} d-flex flex-column`}>
      {" "}
      <div><b>Preview Mode: </b>Not all images will be displayed, 'Photo Finder' feature, and Lightbox will
      only be functional after project activation{" "}</div>
      <div>
        
      </div>

      <UpArrow elementId={"galleryDesigner"} />

    </div>
  );
}

export default Banner;
