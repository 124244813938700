import React from "react";
import { SlArrowUp } from "react-icons/sl";
import styles from "./UpArrow.module.css";

const UpArrow = ({elementId}) => {
    const handleClick = () => {
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      };
    return (
        <div className={`btn d-block d-md-none ${styles.scrollUpButton}`} onClick={handleClick}>
            <SlArrowUp size={50} />
        </div>
    );
};

export default UpArrow;