import Masonry from "react-masonry-css";
import { StorageImage } from "@aws-amplify/ui-react-storage";
import { nanoid } from "nanoid";
import { Flex, Image } from "@chakra-ui/react";
import { render } from "@testing-library/react";
import { getUrl } from "aws-amplify/storage";
import { useEffect } from "react";
import React, { useState, useRef } from "react";
const GallerySection = ({
  imageList,
  ClientData,
  setSelectedLightGalleryImage,
}) => {
  const project_name = ClientData.project_info.PROJECT_NAME;
  const targetIdentityId = ClientData.project_info.IDENTITY_ID;
  const accessLevel = ClientData.project_info.PROJECT_STATUS;
  const prefix_originalImages = project_name + "/originalImages/";
  const prefix_resizedImages = project_name + "/resizedImages/";
  const currentImageList = useRef([]);
  const breakpointColumnsObj = {
    default: 4,
    2500: 4,
    2000: 4,
    1800: 4,
    1500: 3,
    800: 2,
  };

  const columnClassName = "my-masonry-grid_column";
  const gutterSpace = "5px";
  const masonryStyles = {
    ml: `-${gutterSpace}`,
    [`& .${columnClassName}`]: {
      pl: gutterSpace,
      backgroundClip: "padding-box",
    },
  };

  const [renderURLList, setRenderURLList] = useState([]);

  useEffect(() => {
    // Remove duplicates from the renderURLList
    if (renderURLList.length != imageList.length) {
      // Unique the renderURLList
      const uniqueRenderURLList = [
        ...new Map(renderURLList.map((item) => [item["key"], item])).values(),
      ];
    }
  }, [renderURLList]);

  useEffect(() => {
    const renderImages = async () => {
      if (!imageList || imageList.length === 0) {
        // Reset the renderURLList
        setRenderURLList([]);
        return;
      }

      let urlList = [];
      // Make copy of imageList
      const imageListCopy = [...imageList];
      for (const element of imageListCopy) {
        let newKey = element.key;

        if (accessLevel === "protected") {
          newKey = element.key.replace(
            prefix_originalImages,
            prefix_resizedImages
          );
        }

        if (renderURLList.find((item) => item.key === newKey)) {
          continue;
        }

        const url = await getUrl({
          key: newKey,
          options: {
            accessLevel: accessLevel,
            targetIdentityId: targetIdentityId,
          },
        });
        urlList.push({
          key: newKey,
          url: url.url.href,
        });
      }
      // only get unique urls
      setRenderURLList((prev) => [...prev, ...urlList]);
    };

    if (currentImageList.current.length === imageList.length) {
      return;
    } else {
      currentImageList.current = imageList;
    }

    renderImages();
  }, [imageList]);

  return (
    <Flex
      columnClassName={columnClassName}
      as={Masonry}
      breakpointCols={breakpointColumnsObj}
      sx={masonryStyles}
      mt="2rem"
    >
      {renderURLList.map((url) => {
        return (
          <div className="image-container" key={url.key}>
            <Image
              w="100%"
              mb={gutterSpace}
              src={url.url}
              alt=""
              data-key={url.key}
              onClick={() => setSelectedLightGalleryImage(url)}
            />{" "}
          </div>
        );
      })}
    </Flex>
  );
};

export default GallerySection;
