
// const apiGateway = "https://l36iilw9ch.execute-api.us-east-2.amazonaws.com/production"
const apiGateway = "process.env.REACT_APP_AWS_API_GATEWAY"
const uuid = require('uuid');

export const getBucketPictures = async function (bucket,start_range=1,end_range=2) {
  //console.log(`${bucket} start_range-${start_range}`)
  //console.log(`end_range-${end_range}`)
  const requestUrl = `${apiGateway}/get-signed-url?` + new URLSearchParams({
        bucketName: bucket,
        objectKey: "*",
        start_range: start_range,
        end_range: end_range,
        requestType: "GET"
      });
    return await fetch(requestUrl, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    }).then((data) => {
      return data.json();
    }).catch(error => {console.error(error)});

}

export async function AwsGetSignedUrl(bucket,objectKey,requestType) {
    // requestType: name.jpg
    // requestType: GET or PUT
    const requestUrl = `${apiGateway}/get-signed-url?` + new URLSearchParams({
      bucketName: bucket,
      objectKey: `${objectKey}`,
      requestType: requestType
    });
    return await fetch(requestUrl, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    }).then((data) => {
      return data.json();
    }).catch(error => console.error("Error getting file"));
  }


export async function UploadSelfieImage(bucket,presigned_url,objectKey,image){
    const requestURL = presigned_url
    // PUT request: upload file to S3
    return await fetch(requestURL, {
      method: "PUT",
      headers: {
        'Content-Type': 'image/jpg'
      },
      body: image
    }).then( (data) => {
      return data
    }).catch ( (err) => {
      console.error(err)
    });
  };
  


  export async function uploadImageandReturnURL(selfieBucketName, image) {
    const selfie_filename = `${uuid.v4()}.jpg`;
  
    const selfie_presigned_url_response = await AwsGetSignedUrl(
      selfieBucketName,
      selfie_filename,
      "PUT"
    );
  
    if (selfie_presigned_url_response.Message !== "Success") {
      return;
    }
  
    const selfie_presigned_url = selfie_presigned_url_response.presigned_url[0].object_url;
    const selfie_presigned_key = selfie_presigned_url_response.presigned_url[0].object_key;
  
    const uploadFile = await UploadSelfieImage(selfieBucketName, selfie_presigned_url, selfie_presigned_key, image);
  
    // Add a timeout of 5 seconds after uploadFile is set
    await new Promise(resolve => setTimeout(resolve, 5));
  
    if (uploadFile.status !== 200) {
      console.error(`UploadSelfieImage Failed - ${uploadFile.status}`);
      return;
    }
  
    const getSelfieFileLink = await AwsGetSignedUrl(selfieBucketName, selfie_presigned_key, "GET");
  
    if (getSelfieFileLink.Message !== "Success") {
      console.error(`Getting Selfie Image file Failed`);
      return;
    }
  
    const uploaded_selfie_presigned_url = getSelfieFileLink.presigned_url[0].object_url;
  
    if (getSelfieFileLink.Message === "Success") {
      return {
        object_key: selfie_filename,
        object_url: uploaded_selfie_presigned_url,
      };
    }
  }
  


export async function findImageMatches(selfieImageName, searchBucketName, start_range=0, end_range=100){
    //

    const matches = await findMatch(selfieImageName,searchBucketName, start_range, end_range)


    if ( matches.Message != "Success"){
      return;
    }
    
    return matches


    async function findMatch(selfieImageName, searchBucketName ,start_range=0, end_range=0) {

      // It will take in the selfieImage and search anaylze-data repository annd upload it to dev
      const requestUrl = `${apiGateway}/peoplelol?` + new URLSearchParams({
        objectKey: `${selfieImageName}`,
        bucketName: `${searchBucketName}`,
        start_range: start_range,
        end_range: end_range
      });
      return await fetch(requestUrl, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      }).then((data) => {
        return data.json();
  
      }).catch(error => console.error(error));
    }

  }