import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ClassicTheme from "./ClassicTheme/ClassicTheme";
import { getProjectForRender } from "../../api/projects";
import FramedTheme from "./FramedTheme/FramedTheme";
import GalleryRender from "./GalleryRender";
const UserPage = () => {
  const { username, gallery } = useParams();
  const [user, setUser] = useState(null);
  const [clientData, setClientData] = useState(null);
  const [projectTheme, setProjectTheme] = useState(null);

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const data = await getProjectForRender(username, gallery).then(
          (data) => {
            if (!data.Items) {
              return;
            }

            let projectThemeData = data.Items[0].project_info.PROJECT_THEME;
            if ( projectThemeData === undefined) {
              console.error("Error fetching user: project theme not found");
              projectThemeData = "Classic";
            }else {
              setProjectTheme(projectThemeData);
            }
        
            setClientData(data.Items[0]);
          }
        );
      } catch (err) {
        console.error("Error fetching user:", err);
        setUser({ name: "John Doe", email: "sdsd" });
      }
    };

    fetchClientData();
  }, [username]);

  return (
    <div className="bg-white">
      {/* <h1>{username}</h1> */}
      {/* <p>{gallery}</p> */}
      {clientData && projectTheme ? (
        <>
            <GalleryRender themeClientData={clientData} />;
        </>
      ) : null}
    </div>
  );
};

export default UserPage;
