import { getToken } from "./auth";
import { remove } from "aws-amplify/storage";
import { list } from "aws-amplify/storage";
import { getIdentityId } from "./user";

export const getUserProjects = async function () {
  const token = await getToken();
  const apiGateway =
    process.env.REACT_APP_ZMEDIA_API_GATEWAY;
  const requestUrl = `${apiGateway}/api/projects` + new URLSearchParams({});

  return await fetch(requestUrl, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  })
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getProjectForRender = async function (username, project_name) {
  // const token = await getToken();
  const apiGateway =
    process.env.REACT_APP_ZMEDIA_API_GATEWAY;
  const requestUrl =
    `${apiGateway}/api/project/render?` +
    new URLSearchParams({
      project_name: project_name,
      username: username,
    });

  return await fetch(requestUrl, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: `${token}`,
    },
  })
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getProject = async function (project_name) {
  const token = await getToken();
  const apiGateway =
    process.env.REACT_APP_ZMEDIA_API_GATEWAY;
  const requestUrl =
    `${apiGateway}/api/project?` +
    new URLSearchParams({
      project_name: project_name,
    });

  return await fetch(requestUrl, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  })
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      console.error(error);
    });
};

export const createUserProject = async function (
  project_name,
  project_theme,
  theme_info,
  identity_id
) {
  //console.log(`${bucket} start_range-${start_range}`)
  //console.log(`end_range-${end_range}`)
  const token = await getToken();
  const apiGateway =
    process.env.REACT_APP_ZMEDIA_API_GATEWAY;
  const requestUrl = `${apiGateway}/api/project`;
  return await fetch(requestUrl, {
    
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify({
      identity_id: identity_id,
      theme_info: theme_info,
      project_theme: project_theme,
      project_name: project_name,
    }),
  })
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      console.error("error" + error);
    });
};

export const deleteUserProject = async function (project_name) {
  //console.log(`${bucket} start_range-${start_range}`)
  //console.log(`end_range-${end_range}`)
  const token = await getToken();
  const apiGateway =
    process.env.REACT_APP_ZMEDIA_API_GATEWAY;
  const requestUrl = `${apiGateway}/api/project`;

  return await fetch(requestUrl, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify({
      project_name: project_name,
    }),
  })
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      console.error(error);
    });
};

export const removeS3ProjectFolder = async function (project_name) {
  ["private", "protected", "public"].forEach(async (accessLevel) => {
    const listResponse = await list({
      prefix: project_name + "/",
      options: {
        listAll: true,
        accessLevel: accessLevel,
      },
    });
    const items = listResponse.items;
    for (const item of items) {
      await remove({
        key: item.key,
        options: { accessLevel: accessLevel },
      });
    }
  });
};

export const activateUserProject = async function (project_name) {
  // //console.log("activateUserProject");
  //console.log(`${bucket} start_range-${start_range}`)
  //console.log(`end_range-${end_range}`)
  const token = await getToken();
  // //console.log("activateUserProject");
  const identity_id = await getIdentityId();
  // //console.log("activateUserProject");
  const apiGateway =
    process.env.REACT_APP_ZMEDIA_API_GATEWAY;
  const requestUrl = `${apiGateway}/api/project/activate`;

  return await fetch(requestUrl, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${token}`,
      // "InvocationType": "Event"
    },
    body: JSON.stringify({
      project_name: project_name,
      token: token,
      identity_id: identity_id,
      stateMachineArn:
        "arn:aws:states:us-east-1:176304364130:stateMachine:MyStateMachine-skpvohbhy",
    }),
  })
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      console.error(error);
    });
};
