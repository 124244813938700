import * as React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { Alert } from "@aws-amplify/ui-react";

import {
  Button,
  DropZone,
  Flex,
  Heading,
  Text,
  VisuallyHidden,
  View,
} from "@aws-amplify/ui-react";
import { uploadData } from "aws-amplify/storage";
import { Card } from "@aws-amplify/ui-react";
import { Loader } from "@aws-amplify/ui-react";
import Resizer from "react-image-file-resizer";
import {addProjectStorageItem} from "../../api/projects_storage";
import { list } from "aws-amplify/storage";
export default function DropZoneExample({ project_name }) {
  const acceptedFileTypes = ["image/png", "image/jpeg"];
  const [files, setFiles] = React.useState([]);
  const hiddenInput = React.useRef(null);
  const [isUploading, setIsUploading] = React.useState(false);
  const [uploadPercentage, setUploadPercentage] = React.useState(null);
  const [doneLoading, setDoneLoading] = React.useState(false);
  const [allItems, setAllItems] = React.useState(null);
  const addStorageItem = async (filename,size) => {
    try {
      const data = await addProjectStorageItem(project_name, filename,size);
      // return data;
    }
    catch (error) {
      console.error("Error adding storage item:", error);
      throw error; // Or handle error as needed
    }
  }
  
  
  useEffect(() => {
    getAllItems();
  }, []);
  const content = useRef(null);

  useEffect(() => {
    if (allItems) return;
    setDoneLoading(true);
  }, [allItems]);

  useEffect(() => {
    if (files.length === 0) {
      startProcessingFiles(files);
    }
  }, [files]);

  const getAllItems = async () => {
    try {
      const response = await list({
        prefix: project_name + "/",
        options: {
          listAll: true,
          accessLevel: "private", // can be 'private', 'protected', or 'guest' but defaults to `guest`
        },
      });
      setAllItems(response.items);
      // render list items from response.items
    } catch (error) {}
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        3000,
        3000,
        "JPEG",
        90,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const onFilePickerChange = (event) => {
    const { files } = event.target;
    if (!files || files.length === 0) {
      return;
    }

    // Loop through array of files
    Array.from(files).map((file) => {
      setFiles((prevFiles) => {
        return [...prevFiles, { file: file, progress: 0 }];
      });
    });
    // Loop through array of files
    const fileCopy = Array.from(files).map((file) => {
      return { file: file, progress: 0 };
    });
    startProcessingFiles(fileCopy);
  };

  const startProcessingFiles = async (files) => {
    if (!files) return;
    setUploadPercentage(0);

    // Loop through files
    let total_files = files.length;
    let completed_files = 0;

    for (const item of files) {
      // Check if component is still mounted
      if (!content.current) {
        return;
      }

      let file_item = item.file;
      const image = await resizeFile(file_item);
      const blob = await fetch(image).then((r) => r.blob());
      const newFile = new File([blob], file_item.name, {
        type: "image/jpeg",
        lastModified: Date.now(),
      });
      file_item = newFile;

      setIsUploading(true);
      addStorageItem (file_item.name,file_item.size);
      try {
        const result = await uploadData({
          key: project_name + "/originalImages/" + file_item.name,
          data: file_item,
          options: {
            accessLevel: "private", // defaults to `guest` but can be 'private' | 'protected' | 'guest'
            onProgress: (progressEvent) => {
              files[files.indexOf(item)].progress = Math.round(
                (progressEvent.transferredBytes / progressEvent.totalBytes) *
                  100
              );
              if (files[files.indexOf(item)].progress % 10 === 0) {
                setFiles([...files]);
              } else {
              }
            }, // Optional progress callback.
          },
        })
          .result.then((data) => {
            // Find the file in the list and remove it
            files[files.indexOf(item)].progress = 100;
            setFiles([...files]);
            completed_files += 1;
          })
          .catch((err) => {});
      } catch (error) {}

      setUploadPercentage(Math.round((completed_files / total_files) * 100));
    }
    setIsUploading(false);
  };
  // useEffect(() => {
  //   if (!content.current) return;
  //   const currentContent = content.current;
  //   return () => {
  //     currentContent.removeEventListener("click", myFunc);
  //   };
  // }, [isUploading]);

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     if (isUploading) {
  //       event.preventDefault();
  //       event.returnValue = "";
  //     }
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [isUploading]);

  return (
    <div>
      {doneLoading ? (
        <div ref={content}>
          {isUploading ? (
            <div className="d-flex justify-items-center align-items-censter">
              <Alert variation="warning" className="">
                Files are Uploading! Do not move away from this page!
              </Alert>
            </div>
          ) : (
            <DropZone
              acceptedFileTypes={acceptedFileTypes}
              onDropComplete={({ acceptedFiles, rejectedFiles }) => {
                if (isUploading) {
                  return;
                }
                onFilePickerChange({ target: { files: acceptedFiles } });
                //   setFiles({ file: acceptedFiles, progress: 0 });
              }}
            >
              <Flex direction="column" alignItems="center">
                <Text>Drag images here or</Text>
                <Button
                  size="small"
                  disabled={isUploading}
                  onClick={() => hiddenInput.current.click()}
                >
                  Browse
                </Button>
              </Flex>
              <VisuallyHidden>
                <input
                  type="file"
                  tabIndex={-1}
                  ref={hiddenInput}
                  onChange={onFilePickerChange}
                  multiple={true}
                  accept={acceptedFileTypes.join(",")}
                />
              </VisuallyHidden>
            </DropZone>
          )}

          <div
            style={{ marginTop: "1rem", maxHeight: "500px", overflow: "auto" }}
          >
            {" "}
            <h2 style={{ textAlign: "center" }}>
              {files.length} files uploading
            </h2>
            {files
              ? files.map(({ file, progress }) => (
                  <Card key={file.name} variation="outlined">
                    <div className="d-flex justify-content-between">
                      <Text id={file.name} key={file.name}>
                        {file.name}
                      </Text>
                      <Text>{progress}% uploaded</Text>
                    </div>
                  </Card>
                ))
              : null}
          </div>

          <div className="mt-4">
            {isUploading ? (
              <>
                <Text>{uploadPercentage}%</Text>

                <View
                  as="progress"
                  data-progress-bar
                  max="100"
                  value={uploadPercentage}
                />
              </>
            ) : uploadPercentage ? (
              <Heading className="m-2" level={6}>
                Uploading Complete!
              </Heading>
            ) : null}
          </div>
        </div>
      ) : (
        <Loader size="large" />
      )}
    </div>
  );
}
