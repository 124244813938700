import React, { useState, useRef,useEffect } from "react";
import Resizer from "react-image-file-resizer";
import { uploadImageandReturnURL } from "../../../../api/aws"; // replace with your actual import
import "./index.css";
import { uploadData } from "aws-amplify/storage";
import { StorageImage } from "@aws-amplify/ui-react-storage";
import styles from "./index.module.css";
import { StatLabel } from "@chakra-ui/react";
import { uploadImage } from "../../../../api/storage";
const UploadPhotoTab = ({
  ClientData,
  setUploadedSelfieImage,
  // faceRecognitionHasMoreImages,
}) => {
  const SELFIE_BUCKET_NAME = ClientData.SELFIE_BUCKET_NAME;

  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [previewKey, setPreviewKey] = useState(null);
  const uploadFile = useRef(null);
  const [uploadingImage, setUploadingImage] = useState(false);
  const targetIdentityId = ClientData.project_info.IDENTITY_ID;
  const project_name = ClientData.project_info.PROJECT_NAME;
  const project_status = ClientData.project_info.PROJECT_STATUS;
  const [faceRecognitionHasMoreImages, setFaceRecognitionHasMoreImages] = useState(false);

  useEffect(() => {
    setFaceRecognitionHasMoreImages(true);
    // wait 5 seconds and then set faceRecognitionHasMoreImages to false
    const asyncFunction = async () => {
      setTimeout(() => {
        setFaceRecognitionHasMoreImages(false);
      }
      , 10000);
    };
    asyncFunction();
  }, [uploadImage]);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1500,
        1500,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const handleFileChangeAndUpload = async (event) => {
    setUploadingImage(true);
    const file = event.target.files[0];

    if (!file) {
      setUploadingImage(false);
      return;
    }

    try {
      // Resize the image
      const image = await resizeFile(file);
      // Convert image to a file object
      const blob = await fetch(image).then((r) => r.blob());
      const newFile = new File([blob], file.name, {
        type: "image/jpeg",
        lastModified: Date.now(),
      });

      setSelectedFile(newFile);

      const result = await uploadData({
        key:
          targetIdentityId +
          "/" +
          project_name +
          "/uploadedImages/" +
          file.name,
        data: newFile,
        options: {
          accessLevel: "public", // defaults to `guest` but can be 'private' | 'protected' | 'guest'
          // identityId:{targetIdentityId}
        },
      }).result;

      setPreviewKey(result.key);
      setUploadedSelfieImage(result.key);
    } catch (error) {
      console.error("Error handling file change", error);
    }
  };
  // Image on left side and header,subtext,select-file button on right side
  return (
    <>
      <div
        className="upload-photo-tab container-fluid"
        // style={{ minHeight: previewKey!==null ? "300px" : "50vh" }}
      >
        {" "}
        <div className="d-flex flex-column flex-md-row justify-content-center align-items-center gap-5">
          <div className={` ${previewKey === null ? "d-none" : ""} `}>
            {previewKey === null ? null : (
              <StorageImage
                imgKey={previewKey}
                className="selfie-image"
                accessLevel="public"
                identityId={ClientData.project_info.IDENTITY_ID}
              />
            )}

            {/* <img
              src={preview}
              alt="background"
              className={`selfie-image ${preview === null ? "lol" : ""}`}
            /> */}
          </div>
          <div className={styles.selectFileContainer}>
            <div
              className={`${
                preview === null ? "lol" : ""
              } upload-photo-tab-header-container d-flex flex-column `}
            >
              <h1 className="upload-photo-tab-header fancy-theme-main-header text-black text-center">
              Find My Photos
              </h1>{" "}
              <h1 className="upload-photo-tab-header fancy-theme-main-header text-black  text-center">
                with Facial Recognition
              </h1>
            </div>
            <div
              className={`${
                preview === null ? "lol" : ""
              } upload-photo-tab-subtext-container d-flex flex-column justify-items-center align-items-center `}
            >
              <p
                id="upload-photo-tab-subtext-header"
                className="upload-photo-tab-subtext  fancy-theme-main-subtext text-black text-center"
              >
                How to:{" "}
              </p>
              <p className="upload-photo-tab-subtext  fancy-theme-main-subtext text-black text-center">
                1. Click "Select File".{" "}
              </p>
              <p className="upload-photo-tab-subtext  fancy-theme-main-subtext text-black text-center">
                2. Select a selfie or group photo to search the gallery.{" "}
              </p>
              <p className="upload-photo-tab-subtext  fancy-theme-main-subtext text-black text-center">
                3. Once you've selected a photo, the gallery below will display
                all photos with matching faces.{" "}
              </p>
              <p className="upload-photo-tab-subtext-note  fancy-theme-main-subtext text-black text-center">
              Note: You are not uploading your photo to the gallery—just using it to find your photos in the existing collection.
              </p>

              {preview ? "" : null}
            </div>

            <div className="upload-file-btn">
              <input
                style={{ width: "10px" }}
                ref={uploadFile}
                type="file"
                id="uploadFile"
                onChange={handleFileChangeAndUpload}
              />
              <label htmlFor="uploadFile">
                <div className="btn btn-light  upload-tab-button text-black">
                  {uploadingImage && faceRecognitionHasMoreImages
                    ? `Searching Gallery...`
                    : `Select File`}
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadPhotoTab;
