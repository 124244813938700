import React, {useState, useEffect} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import JSZip from 'jszip';
import './MountTheme.css';
import InfiniteScrollGallery from '../../InfiniteScrollGallery';
// import uploadImage from './assets/images/photo.png';
import uploadImage from './assets/images/upload-camera.png';
import Resizer from 'react-image-file-resizer';
import {uploadImageandReturnURL} from '../../../api/aws';

function MountTheme({ClientData}){
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [activeTab, setActiveTab] = useState("second");
    const [selectedImages, setSelectedImages] = useState([]);
    const SELFIE_BUCKET_NAME = ClientData.SELFIE_BUCKET_NAME;
    const BACKGROUND_IMAGE = ClientData.GalleryPageData.BACKGROUND_IMAGE;
    const [selectAnotherPhotoButton, setSelectAnotherPhotoButton] = useState(false);
    const [uploadingImage, setUploadingImage] = useState(false);
    const [selectFileButtonText, setSelectFileButtonText] = useState("Select File...");
    const [backgroundImage, setBackgroundImage] = useState(null);
    const buttonThemeColor = ClientData.GalleryPageData.BUTTON_THEME_COLOR 
    const primaryThemeColor = ClientData.GalleryPageData.PRIMARY_THEME_COLOR 
    const secondaryThemeColor = ClientData.GalleryPageData.SECONDARY_THEME_COLOR

    useEffect(() => {
        if (BACKGROUND_IMAGE) {
            setBackgroundImage("/assets/images/" + BACKGROUND_IMAGE);
        } else {
            setBackgroundImage('/assets/images/white-cloud-extended.png');
        }
    }, [BACKGROUND_IMAGE]);

    useEffect(() => {
        document.title = ClientData.IntroPageData[0].mainText;  
    }, []);

    const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1500,
        1500,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });


    // uploadedSelfieImage
    const [uploadedSelfieImage, setUploadedSelfieImage] = useState({
        image: null,
        preview: null,
    });
    const [isFullGalleryActive, setIsFullGalleryActive] = useState(false);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setTimeout(() => {
            setActiveTab("first");
          }, 1000);
        if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        };
        reader.readAsDataURL(file);
        }
    };

    const onInit = () => {
        // 
    };

    const handleImageClick = (index) => {
        if (selectedImages.includes(index)) {
            setSelectedImages(selectedImages.filter((i) => i !== index));
        } else {
            setSelectedImages([...selectedImages, index]);
        }
    };

    const downloadSelectedImages = () => {
        selectedImages.forEach((index) => {
            const link = document.createElement('a');
            link.href = `assets/images/gallery-image.png`;
            link.download = `image-${index}.${link.href.split('.').pop()}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    };
    
    const handleClickFullGallery = () => {

        setIsFullGalleryActive(true);
        setActiveTab("first");
        window.scrollTo({
          top: window.scrollY + 630,
          behavior: 'smooth',
        });
    };

    const handleClickFindPhoto = () => {
        setIsFullGalleryActive(false);
        setActiveTab("second");
        window.scrollTo({
          top: window.scrollY + 630,
          behavior: 'smooth',
        });
    };

    const handleFileChangeAndUpload = async (event) => {
        setSelectAnotherPhotoButton(false);
        setSelectFileButtonText("Uploading...")
        setUploadingImage(true)
        const file = event.target.files[0];
      
        if (!file) {
          setUploadingImage(false)
          return;
        }
      
        try {
          // Resize the image
          const image = await resizeFile(file);
          // Convert image to a file object
          const blob = await fetch(image).then((r) => r.blob());
          const newFile = new File([blob], file.name, {
            type: 'image/jpeg',
            lastModified: Date.now(),
          });
      
          setSelectedFile(newFile);
      
          if (newFile) {
            const reader = new FileReader();
            reader.onloadend = async () => {
      
              // Change the selfieImage file when uploaded
              try {
                const response = await uploadImageandReturnURL(SELFIE_BUCKET_NAME, newFile);
                setUploadedSelfieImage({
                  ImageName: response.object_key,
                  selfieImageURL: response.object_url,
                });
              } catch (error) {
                console.error("Error uploading image", error);
                }
                
              setPreview(reader.result);
              setUploadingImage(false)

            };
            reader.readAsDataURL(newFile);
          }
        } catch (error) {
          console.error("Error handling file change", error);
        }
        setTimeout(() => {
            setSelectAnotherPhotoButton(true);
          }, 5000);
  
      };

return (<>
        <div className="mount-theme ">

        <section className='banner-section d-flex justify-content-between align-items-center'>
            <div style={{"color": secondaryThemeColor}} className="banner ">Z-Media</div>
            <div style={{"color": secondaryThemeColor}} className="banner">{`${ClientData.GalleryPageData.COMPANY_TAGLINE}`}</div>
        </section>

        <section className='title-section' style={{ backgroundImage: `url('${backgroundImage}')` }} >
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <div className='title-content'>
                                <h1 style={{"color": primaryThemeColor}} className='display-1'>{ClientData.IntroPageData[0].mainText}</h1>
                            <p style={{ "color": primaryThemeColor }} >{ClientData.IntroPageData[0].text}</p>
                            <div className='banner-button d-flex align-items-center justify-content-center'>
                                    <div style={activeTab == "first" ? {"color": secondaryThemeColor}  : { "backgroundColor": buttonThemeColor }}  className={`btn btn-primary-gallery ${activeTab == "first" ? "active-btn" : "nonactive-btn" }  `} onClick={handleClickFullGallery}>Full Gallery</div>
                                    <div style={activeTab == "first" ? { "backgroundColor": buttonThemeColor } : {"color": secondaryThemeColor} } className={`btn btn-primary-gallery ${activeTab == "first" ? "nonactive-btn" : "active-btn"}`} onClick={handleClickFindPhoto}>Find Your Photos</div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

        <section className='tab-section'>
            <div className='gallery-tabs'>
                {
                    isFullGalleryActive ||preview ? null: (
                        <div className='upload-photo-tab'>
                            <Row className='align-items-center'>
                            <Col xs={12} className="">
                                <h1  style={{"color" : secondaryThemeColor}} className='poppins find-my-photos-title xdisplay-6 '>Find My Photos with Facial Recognition</h1>
                            </Col>
                                <Col xs={12}>
                                    <div className='upload-box-main'>
                                        <div className='upload-photo-box '>
                                            {preview ? (
                                                <>
                                                    {selectedFile.type.includes('image') ? (
                                                        <img src={preview} alt="Preview" />
                                                    ) : (
                                                        <p>{selectedFile.name}</p>
                                                    )}
                                                </>
                                            ) : <img className="fit-container" src={uploadImage} alt='Upload' />}
                                        </div>
                                    </div>
                            </Col>
                                <Col xs={12}>
                                <div className='upload-file-btn'>
                                    <input type="file" id='uploadFile' onChange={handleFileChangeAndUpload} />
                                    <label style={{"backgroundColor": buttonThemeColor }} htmlFor="uploadFile" className={`btn select-file-button`} >
                                        {/* <img src='assets/images/Select-File-Button.png' alt='Select File' /> */}
                                            
                                            {selectFileButtonText}
                                    </label>
                                    </div>
                                    {preview ? null: (
                                        <div className='upload-box'>
                                            <p style={{"color": secondaryThemeColor}} className=''>
                                                <strong>How to:</strong><br />
                                                1. Click on "Select File" to begin.<br />
                                                2. Take a selfie or choose a group photo that includes the faces you wish to find.<br />
                                                3. After selecting your photo, the gallery will update to show all images where those faces appear.
                                        </p>
                                        </div>
                                    ) }
                                </Col>
                            </Row>
                        </div>
                        )
                    }
                    <InfiniteScrollGallery
                    isFullGalleryActive={isFullGalleryActive}
                    uploadedSelfieImage={uploadedSelfieImage}
                    ClientData={ClientData}
                />
                

               { isFullGalleryActive == false && selectAnotherPhotoButton ? (
                        <>
                        <div className='upload-file-btn no-preview'>
                                    <input type="file" id='uploadFile' onChange={handleFileChangeAndUpload} />
                                    <label style={{backgroundColor : buttonThemeColor}} htmlFor="uploadFile" className={`btn select-file-button`} >
                                        {/* <img src='assets/images/Select-File-Button.png' alt='Select File' /> */}
                                            Select another image...
                                    </label>
                                    </div>
                        </>
                ) : null
                } 
            </div>
        </section>
    <div className='footer-section'>
        {/* <button>asdasdasdssd</button> */}
        </div>

        
        
        
        
    </div>
    </>);
}




export default MountTheme;