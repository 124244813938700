import LoginPage from "./components/Pages/LoginPage/LoginPage";
import { Amplify } from "aws-amplify";
import config from "./amplifyconfiguration.json";
import "./App.css";
import "../src/assets/scss/style.scss";
import "./assets/css/animate.css";
import "./assets/css/bootstrap.min.css";
import "@aws-amplify/ui-react/styles.css"; // Needed for gallery pages

// CSS File Here
import "./assets/css/elegantIcons.css";
import "./assets/css/icofont.css";
import { Authenticator, View } from "@aws-amplify/ui-react";
import "./assets/scss/style.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
// Impot varialbe from test.js
// Import ClientData from another file
import MountTheme from "./components/Pages/MountTheme/MountTheme";
import ContactPage from "./components/Pages/ContactPage/ContactPage";
import Dashboard from "./components/Pages/Dashboard/Dashboard";
import AccountPage from "./components/Pages/Account/Account";
import GalleryDesigner from "./components/Pages/GalleryDesigner/GalleryDesigner";
import Test from "./components/OverviewTable/OverviewTable";

import { Account } from "./context/AccountContext";
import UserPage from "./components/Pages/UserPage";
import Layout from "./layouts/Layout";
// import ContactPage from "./components/Pages/ContactPage/ContactPage";
import { ThemeProvider } from "@aws-amplify/ui-react";
import MarketingLayout from "./layouts/MarketingLayout";

import MarketingPage from "./components/Pages/MarketingPage/MarkingPage";
import AboutUs from "./components/Pages/AboutUs/AboutUs";
import "./@overrides.scss";
import DIYPricing from "./components/Pages/DIYPricing/DIYPricing";
import RedirectPage from "./components/Pages/RedirectPage";
import MetaPixel from "./components/Meta/MetaPixel";
import ReactGA from "react-ga4";
import SignUpPage from "./components/Pages/SignUpPage/SignUpPage";
import LandingPage from "./components/Pages/LandingPage_1/LandingPage";
import LandingPage2 from "./components/Pages/LandingPage_2/LandingPage";
import LandingPage3 from "./components/Pages/LandingPage_3/LandingPage";
import LandingPage3v2 from "./components/Pages/LandingPage_3_v2/LandingPage";
import LandingPage_Corporate from "./components/Pages/LandingPage_Corporate/LandingPage";
import LandingPage_Church from "./components/Pages/LandingPage_Church/LandingPage";
import LandingPage_Mosque from "./components/Pages/LandingPage_Mosque/LandingPage";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import SupportAdmin from "./components/SupportAdmin";
import PrivacyPage from "./components/Pages/PrivacyPage/PrivacyPage";
function App({ Component, pageProps }) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
    debug: true,
  });

  return (
    <div className="App vh-100">
      <ChakraProvider theme={theme}>
        <MetaPixel />
        <Authenticator.Provider>
          <BrowserRouter>
            {/* <Account> */}
            <ThemeProvider>
              <Routes>
                <Route path="/" element={<MarketingLayout />}>
                  <Route index element={<LandingPage_Corporate />} />

                  <Route path="/support" element={<SupportAdmin />} />
                  <Route path="/lp32" element={<LandingPage3v2 />} />
                  <Route path="/lpcorp" element={<LandingPage_Corporate />} />
                  <Route path="/lpchurch" element={<LandingPage_Church />} />
                  <Route path="/lpmosque" element={<LandingPage_Mosque />} />
                </Route>
                <Route path="/" element={<Layout />}>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/account" element={<AccountPage />} />
                  <Route
                    path="/contact"
                    element={<ContactPage DIY={true} />}
                  />{" "}
                  <Route path="/pricing" element={<DIYPricing />} />
                  {/* <Route path="/m" element={<PricingPage />} /> */}
                  <Route path="/about" element={<AboutUs />} />
                  <Route path="/privacy" element={<PrivacyPage />} />
                  <Route path="/signup" element={<SignUpPage />} />
                  <Route path="/login" element={<LoginPage />} />
                </Route>
                <Route path="/test" element={<Test />} />
                <Route path="/account-link" element={<AccountPage />} />
                <Route path="/gallery-designer" element={<GalleryDesigner />} />
                <Route path="/:username/:gallery" element={<UserPage />} />
                <Route
                  path="/maya"
                  element={<RedirectPage location={"/chirayilz/maya"} />}
                />{" "}
              </Routes>
            </ThemeProvider>
            {/* </Account> */}
          </BrowserRouter>
        </Authenticator.Provider>
      </ChakraProvider>
    </div>
  );
}

export default App;
