import { ReactNode } from "react";
import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
} from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";
import { FaTimesCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
function PriceWrapper({ children }) {
  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: "center", lg: "flex-start" }}
      borderColor={useColorModeValue("gray.200", "gray.500")}
      borderRadius={"xl"}
    >
      {children}
    </Box>
  );
}
const SectionHeader = ({ header, subHeader }) => {
  return (
    <div className="d-flex flex-column justify-items-center align-items-center p-1  ">
      <h2
        className="alegreya-sc-regular fs-5 text-center"
        style={{ color: "gray" }}
      >
        {header}
      </h2>
      <h3
        className="alegreya-sans-regular fs-6  text-center"
        style={{ color: "black" }}
      >
        {subHeader}
      </h3>
    </div>
  );
};

export default function ThreeTierPricing() {
  return (
    <Box>
      <SectionHeader
        header="Pay Per Gallery"
        subHeader="Photographers can host their clients' photo galleries without the need for a subscription. Simply purchase each gallery as needed."
      />
      <Stack
        direction={{ base: "column", md: "row" }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, lg: 10 }}
        py={10}
      >
        <PriceWrapper>
          <Box py={4} px={12}>
            <Text fontWeight="500" fontSize="2xl">
              Basic
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="lg" fontWeight="600">
                $
              </Text>
              <Text fontSize="4xl" fontWeight="900">
                25
              </Text>
              <Text fontSize="lg" color="gray.500">
                /gallery
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue("gray.50", "gray.700")}
            py={4}
            borderBottomRadius={"xl"}
          >
            <List spacing={3} textAlign="start" fontSize={"12px"} px={12}>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Online gallery access for one year
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                500 Photos 
              </ListItem>
              <ListItem>
                <ListIcon as={FaTimesCircle} color="red.500" />
                AI Photo Finder Feature
              </ListItem>
            </List>
            <Box w="80%" pt={7}>
              <Link to="/contact">
                <Button
                  fontSize={"sm"}
                  w="full"
                  colorScheme="blue"
                  variant="outline"
                >
                  Contact us now!
                </Button>
              </Link>
            </Box>
          </VStack>
        </PriceWrapper>

        <PriceWrapper>
          <Box position="relative">
            <Box
              position="absolute"
              top="-16px"
              left="50%"
              style={{ transform: "translate(-50%)" }}
            >
              <Text
                textTransform="uppercase"
                bg={useColorModeValue("red.300", "red.700")}
                px={3}
                py={1}
                color={useColorModeValue("gray.900", "gray.300")}
                fontSize="sm"
                fontWeight="600"
                rounded="xl"
              >
                Most Popular
              </Text>
            </Box>
            <Box py={4} px={12}>
              <Text fontWeight="500" fontSize="2xl">
                Premium
              </Text>
              <Text fontSize="md" color="gray.500">
                (Special Limited Time Offer){" "}
              </Text>
              <HStack justifyContent="center">
                <Text fontSize="xl" fontWeight="600">
                  $
                </Text>
                <Text fontSize="4xl" fontWeight="900">
                    <span className="discounted-price">50</span>{" "}
                </Text>
                <Text fontSize="lg" color="gray.500">
                  /gallery
                </Text>
              </HStack>
            </Box>
            <VStack
              bg={useColorModeValue("gray.50", "gray.700")}
              py={4}
              borderBottomRadius={"xl"}
            >
              <List spacing={3} textAlign="start" fontSize={"12px"} px={12}>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Online gallery access for one year
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  1000 Photos
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  AI Photo Finder Feature
                </ListItem>
              </List>
              <Box w="80%" pt={7}>
                <Link to="/contact">
                  <Button fontSize={"sm"} w="full" colorScheme="blue">
                  Contact us now!
                  </Button>
                </Link>
              </Box>
            </VStack>
          </Box>
        </PriceWrapper>
        <PriceWrapper>
          <Box py={4} px={12}>
            <Text fontWeight="500" fontSize="2xl">
              Standard
            </Text>

            <HStack justifyContent="center">
              <Text fontSize="xl" fontWeight="600">
                $
              </Text>
              <Text fontSize="4xl" fontWeight="900">
                35
              </Text>
              <Text fontSize="lg" color="gray.500">
                /gallery
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue("gray.50", "gray.700")}
            py={4}
            borderBottomRadius={"xl"}
          >
            <List spacing={3} textAlign="start" fontSize={"12px"} px={12}>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Online gallery access for one year
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                500 Photos
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                AI Photo Finder Feature
              </ListItem>
            </List>
            <Box w="80%" pt={7}>
              <Link to="/contact">
                <Button
                  fontSize={"sm"}
                  w="full"
                  colorScheme="blue"
                  variant="outline"
                >
                  Contact us now!
                </Button>
              </Link>
            </Box>
          </VStack>
        </PriceWrapper>
      </Stack>

      {/* <div
        className="d-flex justify-items-center align-items-center gap-1"
        style={{ marginTop: "-20px" }}
      >
        <span style={{ fontSize: "14px" }}>Need More?</span>
        <Button
          fontSize={"sm"}
          variant={"link"}
          as={"a"}
          href={"/contact"}
          bg={"white"}
          color={"black"}
          style={{ fontWeight: "700" }}
          _hover={{
            bg: "white",
            color: "gray",
          }}
        >
          Contact Us
        </Button>
      </div> */}
    </Box>
  );
}
