import React, { useEffect, useState, useRef } from "react";
import styles from "./ImageUploader.module.css";
import { getUrl } from "aws-amplify/storage";
import { list } from "aws-amplify/storage";
import { nanoid } from "nanoid";
import { Card, Pagination } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import Masonry from "react-masonry-css";
import { Flex, Image } from "@chakra-ui/react";
import { remove } from "aws-amplify/storage";
import { get } from "jquery";
function CardComponent({ project_name }) {
  const [uploadedImages, setUploadedImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [totalImages, setTotalImages] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [hasMorePages, setHasMorePages] = useState(true);
  const [allItems, setAllItems] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [nextTokenState, setNextTokenState] = useState(null);
  const [selectedForDelete, setSelectedForDelete] = useState([]);
  const TEMPKEY = "TEMP/";
  const PAGE_SIZE = 50;
  const breakpointColumnsObj = {
    default: 7,
    2500: 7,
    2000: 7,
    1800: 7,
    1500: 5,
    800: 4,
  };
  useEffect(() => {
    getAllItems();
  }, []);

  useEffect(() => {}, [uploadedImages]);
  useEffect(() => {
    setTotalPages(Math.ceil(allItems.length / PAGE_SIZE));

    // Update totalImages and totalSize
    setTotalImages(allItems.length);

    let tempSize = 0
    allItems.map((item) => {
      tempSize =  tempSize + item.size;
    }
    );
    setTotalSize(tempSize);

  }, [allItems]);
  const firstRenderPage = useRef(true);

  useEffect(() => {
    if (allItems.length === 0) {
      return;
    }
    if (firstRenderPage.current) {
      handleOnChange(1);
      firstRenderPage.current = false;
    }
  }, [allItems]);

  const refreshPage = async () => {
    await getAllItems().then(() => {
      handleOnChange(currentPageIndex);
    });
    // window.location.reload();
  };
  const handleOnChange = async (pageIndex) => {
    if (allItems.length > pageIndex * PAGE_SIZE) {
      let data = allItems.slice(
        (pageIndex - 1) * PAGE_SIZE,
        pageIndex * PAGE_SIZE
      );
      setUploadedImages([]);
      for (const file of data) {
        let data = await getSignedUrl(file.key);
        // Add key to the data object
        data.key = file.key;
        await new Promise((resolve) => setTimeout(resolve, 100));
        setUploadedImages((prevImages) => [...prevImages, data]);
      }
    } else {
      let data = allItems.slice((pageIndex - 1) * PAGE_SIZE, allItems.length);
      setUploadedImages([]);
      for (const file of data) {
        let data = await getSignedUrl(file.key);
        // Add key to the data object
        data.key = file.key;
        await new Promise((resolve) => setTimeout(resolve, 100));
        setUploadedImages((prevImages) => [...prevImages, data]);
      }
    }

    setCurrentPageIndex(pageIndex);
  };

  const handleNextPage = async () => {
    let pageIndex = currentPageIndex + 1;
    handleOnChange(pageIndex);
    setCurrentPageIndex(pageIndex);
  };

  const handlePreviousPage = async () => {
    let pageIndex = currentPageIndex - 1;
    handleOnChange(pageIndex);
    setCurrentPageIndex(pageIndex);
  };

  const getSignedUrl = async (key) => {
    return await getUrl({
      key: key,
      options: {
        accessLevel: "private", // can be 'private', 'protected', or 'guest' but defaults to `guest`
        validateObjectExistence: false, // defaults to false
        expiresIn: 900, // validity of the URL, in seconds. defaults to 900 (15 minutes) and maxes at 3600 (1 hour)
      },
    });
  };

  const handleCheckmarkClick = (imageUrl) => {
    const isSelected = selectedImages.includes(imageUrl);
    const updatedSelection = isSelected
      ? selectedImages.filter((selectedImage) => selectedImage !== imageUrl)
      : [...selectedImages, imageUrl];

    setSelectedImages(updatedSelection);
  };

  const handleGalleryImageClick = (e) => {
    const imageKey = e.target.id;
    const imageUrl = e.target.src;

    if (selectedForDelete.find((element) => element === imageKey)) {
      setSelectedForDelete((prev) => prev.filter((item) => item !== imageKey));
      return;
    }
    setSelectedForDelete((prev) => [...prev, imageKey]);
    // e.target.classList.add(styles.clicked);

    // remove styles.clicked from all other images
  };

  const deleteAllFiles = async () => {
    if (allItems.length === 0) {
      return;
    }
    if (!window.confirm("Are you sure you want to delete all images?")) {
      return;
    }
    // Loop through allItems and delete each file
    for (const file of allItems) {
      try {
        await remove({
          key: file.key,
          options: { accessLevel: "private" },
        }).then(() => {});
      } catch (error) {}
    }
    setUploadedImages([]);
    setAllItems([]);
  };

  const deleteSelectedFiles = async () => {
    if (selectedForDelete.length === 0) {
      return;
    }
    if (
      !window.confirm("Are you sure you want to delete the selected images?")
    ) {
      return;
    }
    // Loop through selectedImages and delete each file
    for (const file of selectedForDelete) {
      try {
        await remove({ key: file, options: { accessLevel: "private" } });
      } catch (error) {}
    }

    setUploadedImages(
      uploadedImages.filter((image) => !selectedForDelete.includes(image.key))
    );
    setAllItems(
      allItems.filter((item) => !selectedForDelete.includes(item.key))
    );
    // refreshPage();
  };

  const getAllItems = async () => {
    try {
      const response = await list({
        prefix: project_name + "/originalImages/",
        options: {
          listAll: true,
          accessLevel: "private", // can be 'private', 'protected', or 'guest' but defaults to `guest`
        },
      });
      setAllItems(
        response.items.filter((item) => {
          if (item.key.startsWith(project_name + "/" + TEMPKEY)) {
          } else {
            return item;
          }
        })
      );


     

      // render list items from response.items
    } catch (error) {}
  };

  const GallerySection = () => {
    const columnClassName = "my-masonry-grid_column";
    const gutterSpace = "10px";
    const masonryStyles = {
      ml: `-${gutterSpace}`,
      [`& .${columnClassName}`]: {
        pl: gutterSpace,
        backgroundClip: "padding-box",
      },
    };

    return (
      <Flex
        columnClassName={columnClassName}
        as={Masonry}
        breakpointCols={breakpointColumnsObj}
        sx={masonryStyles}
        mt="2rem"
      >

        {uploadedImages.map((image) => (
          <div
            className={`${styles.ImageContainer} image-contaisnser`}
            key={nanoid()}
          >
            <Card variation="elevated" className="p-2 mt-1 mb-1">
              <Image
                id={image.key}
                w="100%"
                className={`${styles.lightGalleryMain} ${
                  selectedImages.includes(image.url.href) ? "selected" : ""
                } ${
                  selectedForDelete.find((element) => element === image.key)
                    ? styles.clicked
                    : ""
                }`}
                mb={gutterSpace}
                src={image.url.href}
                alt=""
                onClick={handleGalleryImageClick}
              />
            </Card>

            <div
              onClick={() => handleCheckmarkClick(image)}
              className={`${
                selectedImages.includes(image)
                  ? "checked"
                  : " d-none d-md-inline unchecked"
              }`}
            ></div>
          </div>
        ))}
      </Flex>
    );
  };

  return (
    <Card
      variation="elevated"
      className={`bg-white mt-3 ${styles.cardGallery}`}
    >
      <div className={`${styles.pagination}`}>
        <Pagination
          currentPage={currentPageIndex}
          totalPages={totalPages}
          onNext={handleNextPage}
          onPrevious={handlePreviousPage}
          onChange={handleOnChange}
        />
      </div>
      <div
        className={`d-flex justify-content-between align-items-center flex-column flex-md-row `}
      >
        <div className="d-flex flex-column">

        <h2 className={styles.headerImageCount}>
          Number of Images - {totalImages}
        </h2>
        <h2 className={styles.headerImageCount}>
          Total Size - {(totalSize / 1024 ** 3).toFixed(3)} GB{" "}
        </h2></div>
        <div className={`${styles.buttonContainer}`}>
          <button className="btn btn-dark " onClick={deleteAllFiles}>
            Delete All
          </button>
          <button className="btn btn-dark" onClick={deleteSelectedFiles}>
            Delete Selected
          </button>
        </div>
      </div>
      <GallerySection />
      <br />
    </Card>
  );
}

export default CardComponent;
