import { React } from "react";
import { useState, useEffect, useRef } from "react";
import main_image from "./main_image.jpg"; // Assuming you have an image file
import InfiniteScrollGallery from "../../InfiniteScrollGallery";
import "./UploadPhotoTab.css";
import UploadPhotoTabFancy from "./UploadPhotoTabFancy";
import styles from "./ClassicTheme.module.css";
import { StorageImage } from "@aws-amplify/ui-react-storage";
import { SlArrowUp } from "react-icons/sl";
import { theme } from "@chakra-ui/react";
function ClassicTheme({ ClientData }) {
  // if client data is not passed in then set it to a default value
  const [mainImage, setMainImage] = useState(main_image);

  const project_status = ClientData.project_info.PROJECT_STATUS;
  const mainImageKey = ClientData.theme_info[0].MAIN_IMAGE.value;
  const project_name = ClientData.project_info.PROJECT_NAME;
  const [faceRecognitionHasMoreImages, setFaceRecognitionHasMoreImages] =
    useState(true);
  const theme_gallery_active = ClientData?.IsActive || false;
  const theme_ai_enabled = ClientData.IsAiEnabled || false;
  const theme_company_tagline =
    ClientData.theme_info[0].COMPANY_TAGLINE?.value || "";
  const theme_cover_page_main_text =
    ClientData.theme_info[0].mainText?.value || "";
  const theme_cover_page_subtext = ClientData.theme_info[0].text?.value || "";
  const theme_primary_color =
    ClientData.theme_info[0].PRIMARY_THEME_COLOR?.value || "white";
  const theme_share_icon_color =
    ClientData.theme_info[0].SHARE_ICON_COLOR?.value || "black";
  const theme_secondary_color =
    ClientData.theme_info[0].SECONDARY_THEME_COLOR?.value || "black";
  const theme_primary_button_color =
    ClientData.theme_info[0].BUTTON_THEME_COLOR?.value || "black";
  const view_gallery_button_ref = useRef(null);
  const find_my_photos_button_ref = useRef(null);
  const themeNavBarRef = useRef(null);

  const [isFullGalleryActive, setIsFullGalleryActive] = useState(true);
  // uploadedSelfieImage
  const [uploadedSelfieImage, setUploadedSelfieImage] = useState(null);
  const handleGalleryClick = (e) => {
    const buttonid = e.target.id;
    if (buttonid === "view-gallery-button") {
      setIsFullGalleryActive(true);
      setTimeout(() => {
        themeNavBarRef.current.scrollIntoView({ behavior: "smooth" });
      }, 200);
    } else if (buttonid === "find-my-photos-button") {
      setIsFullGalleryActive(false);
      setTimeout(() => {
        themeNavBarRef.current.scrollIntoView({ behavior: "smooth" });
      }, 200);
    }
  };

  const ThemeNavBar = () => {
    return (
      //   <Navbar className="navbar navbar-expand-lg navbar-light bg-light">
      <div
        className={`navbar navbar-expand-lsg navbar-light bg-light ${styles.navbarex}`}
      >
        {" "}
        <div className="container-fluid d-flex flex-wrap justify-content-center justify-content-sm-between gap-1 gap-sm-0 ">
          {" "}
          <div
            className="navbar-brand"
            style={{
              fontSize: "14px",
              fontFamily: "Syne,sans serf",
              // letterSpacing: ".1px",
              // textTransform: "uppercase",
              // fontWeight: "600",
            }}
            href="#"
          >
            {theme_company_tagline}
          </div>
          <div
            className={`collapse navbar-collapse ${
              isFullGalleryActive ? "" : ""
            }`}
            id="navbarTogglerDemo02"
          ></div>
          <div className="d-flex flex-row gap-3 justify-items-center">
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill={theme_share_icon_color}
              className="bi bi-download ml-auto d-none d-md-block"
              viewBox="0 0 16 16"
            >
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
            </svg>{" "}
            <svg
              id="last-share-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill={theme_share_icon_color}
              className={`bi bi-share-fill mr-5 ${styles.lastShareIcon}`}
              viewBox="0 0 16 16"
            >
              <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.5 2.5 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5" />
            </svg>{" "} */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div id="fancy-theme-cover" className={`${styles.bgwhite}`}>
        <div className={`${styles.mainImageContainer}`}>
          {mainImageKey === "default" ? (
            <img
              src={mainImage}
              alt="background"
              className={`${styles.backgroundImage}`}
            />
          ) : (
            <StorageImage
              className={`${styles.backgroundImage}`}
              imgKey={`${project_name}/${mainImageKey}`}
              accessLevel={project_status}
              identityId={ClientData.project_info.IDENTITY_ID}
            />
          )}

          <div
            className={`${styles.mainContent} red-guide flex-column justify-items-center align-items-center`}
          >
            {" "}
            <h1
              className={`${styles.ClassicThemeMainHeader}`}
              style={{ color: theme_primary_color }}
            >
              {theme_cover_page_main_text}
            </h1>
            <p
              className={`${styles.ClassicThemeMainSubtext}`}
              style={{ color: theme_secondary_color }}
            >
              {theme_cover_page_subtext}
            </p>
            <div className="d-flex flex-row gap-3 justify-items-center">
              <button
                ref={view_gallery_button_ref}
                onClick={handleGalleryClick}
                id="view-gallery-button"
                className={` btn ${styles.ClassicThemeButton}`}
                style={{
                  color: theme_primary_button_color,
                  borderColor: theme_primary_button_color,
                }}
              >
                View Gallery
              </button>
              
              {theme_ai_enabled || theme_gallery_active == false ? (
                <button
                  ref={find_my_photos_button_ref}
                  onClick={handleGalleryClick}
                  id="find-my-photos-button"
                  className={` btn ${styles.ClassicThemeButton}`}
                  style={{
                    color: theme_primary_button_color,
                    borderColor: theme_primary_button_color,
                  }}
                >
                  Photo Finder
                </button>
              ) : null}
            </div>
          </div>
        </div>
        {isFullGalleryActive === null ? null : (
          <>
            <div ref={themeNavBarRef}>
              <ThemeNavBar />
            </div>

            {isFullGalleryActive == false ? (
              <div className={`${styles.uploadphototabheader}`}>
                <UploadPhotoTabFancy
                  ClientData={ClientData}
                  setUploadedSelfieImage={setUploadedSelfieImage}
                  faceRecognitionHasMoreImages={faceRecognitionHasMoreImages}
                />
              </div>
            ) : null}

            <div className={`container-fluid ${styles.galleryContainerMain} `}>
              <InfiniteScrollGallery
                className={styles.infinitescrollcomponent}
                isFullGalleryActive={isFullGalleryActive}
                uploadedSelfieImage={uploadedSelfieImage}
                setFaceRecognitionHasMoreImages={
                  setFaceRecognitionHasMoreImages
                }
                ClientData={ClientData}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ClassicTheme;
