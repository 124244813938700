import { getToken } from "./auth";
import { remove } from "aws-amplify/storage";
import { list } from "aws-amplify/storage";
import { getIdentityId } from "./user";

export const getUserProjectStorage = async function (project_name) {
  const token = await getToken();
  const apiGateway = process.env.REACT_APP_ZMEDIA_API_GATEWAY;
  const requestUrl =
    `${apiGateway}/api/project/storage?` +
    new URLSearchParams({
      project_name: project_name,
    });

  return await fetch(requestUrl, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  })
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      console.error(error);
    });
};

export const addProjectStorageItem = async function (
  project_name,
  filename,
  size
) {
  //console.log(`${bucket} start_range-${start_range}`)
  //console.log(`end_range-${end_range}`)
  const token = await getToken();
  //console.log(token);
  const apiGateway = process.env.REACT_APP_ZMEDIA_API_GATEWAY;
  const requestUrl = `${apiGateway}/api/project/storage`;
  return await fetch(requestUrl, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
    body: JSON.stringify({
      "project_name": project_name,
      "filename": filename,
      "size": size,
    }),
  })
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      console.error("error" + error);
    });
};
