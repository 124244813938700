import React, { useEffect, useState, useRef } from "react";
import ClassicTheme from "../ClassicTheme/ClassicTheme";
import FramedTheme from "../FramedTheme/FramedTheme";
import SideGalleryDesigner from "../../SideGalleryDesigner/SideGalleryDesigner";
import styles from "./GalleryDesigner.module.css";
import { useLocation } from "react-router-dom";
import { getProject } from "../../../api/projects";
import { getDefaultThemes } from "../../../api/theme";
import ImageUploader from "../../ImageUploader/ImageUploader";
import { Loader } from "@aws-amplify/ui-react";
import Banner from "./Banner/Banner";
import { SlArrowUp } from "react-icons/sl";
import UpArrow from "../../UpArrow/UpArrow";
import GalleryRender from "../GalleryRender";
import ParallelTheme from "../ParallelTheme/ParallelTheme";
import ReactGA from "react-ga4";
import TopClassicTheme from "../TopClassicTheme/TopClassicTheme";

function GalleryDesigner({}) {
  const [themeClientData, setThemeClientData] = useState(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const location = useLocation();
  const stateValue = location?.state;
  const [defaultThemeData, setDefaultThemeData] = React.useState(null);
  const [userClientData, setUserClientData] = React.useState(null);
  const [selectedTheme, setSelectedTheme] = React.useState(null);
  const [isImageUploader, setIsImageUploader] = React.useState(false);
  const gallery_name = useRef(null);
  const [isNewProject, setIsNewProject] = useState(true);
  const supportedThemes = ["Classic", "Framed", "Parallel", "TopClassic"];

  if (stateValue && stateValue.gallery_name && gallery_name.current === null) {
    gallery_name.current = stateValue.gallery_name;
   
    setIsNewProject(false);
  }

  const [themeList, setThemeList] = useState(null);

  useEffect(() => {
    if (themeClientData !== null) {
      setIsDataLoaded(true);
    }
  }, [themeClientData]);

  useEffect(() => {

    ReactGA.send({
      hitType: "pageview",
      page: "/gallerydesigner",
      title: `gd_${location.state.gallery_name || "new_gallery"}`,
    });

    const getAllAPI = async () => {
      // Get Theme List

      getDefaultThemes().then((dataThemeList) => {
        let filtered_theme_list = dataThemeList.Items.filter((item) => {
          if (supportedThemes.includes(item.project_info.PROJECT_THEME)) {
            return item;
          }
        });

        setDefaultThemeData(filtered_theme_list);
      });

      return;
    };

    getAllAPI();
  }, []);

  useEffect(() => {
    if (themeList === null) {
      return;
    }
  }, [themeList]);

  useEffect(() => {
    // Handle selected theme when changed
    if (selectedTheme === null) {
      return;
    }
    // Merge user data with default theme data
    let userDataMergedDefaultThemeData = mergeUserDataWithDefaultTheme(
      themeClientData,
      selectedTheme,
      defaultThemeData
    );

    setThemeClientData({ ...userDataMergedDefaultThemeData });
  }, [selectedTheme]);

  function mergeUserDataWithDefaultTheme(
    userData,
    projectTheme,
    defaultThemeData
  ) {
    let selectedDefaultTheme = JSON.parse(
      JSON.stringify(defaultThemeData)
    ).filter((item) => item.project_info.PROJECT_THEME === projectTheme)[0];
    let userDataMergedDefaultThemeData = selectedDefaultTheme;
    // Update project info
    for (const [key, value] of Object.entries(
      selectedDefaultTheme.project_info
    )) {
      if (!userData.project_info[key]) {
        continue;
      }
      // Ignore project_theme
      if (key === "PROJECT_THEME") {
        continue;
      }

      userDataMergedDefaultThemeData.project_info[key] =
        userData.project_info[key];
    }

    // Update theme info
    for (const index in selectedDefaultTheme.theme_info) {
      if (!selectedDefaultTheme.theme_info[index]) {
        continue;
      }
      for (const [key, value] of Object.entries(
        selectedDefaultTheme.theme_info[index]
      )) {
        if (!selectedDefaultTheme.theme_info[index][key]) {
          continue;
        }

        if (!selectedDefaultTheme.theme_info[index][key].value) {
          continue;
        }
        if (!selectedDefaultTheme.theme_info[index][key].customizeType) {
          continue;
        }
        // If the key doesnt exist on the userSide then add the key
        if (!userData.theme_info[index][key]) {
          continue;
        }

        // if the key has ImgKey then add the key
        if (selectedDefaultTheme.theme_info[index][key].imageKey) {
          userDataMergedDefaultThemeData.theme_info[index][key].imageKey =
            userData.theme_info[index][key].imageKey;
        }

        userDataMergedDefaultThemeData.theme_info[index][key].value =
          userData.theme_info[index][key].value;
      }
    }

    return userDataMergedDefaultThemeData;
  }

  useEffect(() => {
    if (defaultThemeData === null) {
      return;
    }

    const getUserData = async () => {
      if (gallery_name.current !== null) {
        return getProject(gallery_name.current).then((data) => {
          if (data.Items[0] !== null) {
            return data.Items[0];
          } else {
            return null;
          }
        });
      }
    };

    setThemeList(
      defaultThemeData.map((item) => {
        return item.project_info.PROJECT_THEME;
      })
    );

    getUserData().then((userClientData) => {
      // Initial setup of the theme data
      if (!userClientData) {
        setThemeClientData(defaultThemeData[0]);
      } else {
        let userDataMergedDefaultThemeData = mergeUserDataWithDefaultTheme(
          userClientData,
          userClientData.project_info.PROJECT_THEME,
          defaultThemeData
        );
        setThemeClientData({ ...userDataMergedDefaultThemeData });
      }
    });
  }, [defaultThemeData]);

  const RenderTheme = () => {
    let currentTheme = themeClientData.project_info.PROJECT_THEME;
    //console.log("currentTheme", currentTheme);

    switch (currentTheme) {
      case "Classic":
        return (
          <>
            {/* <Banner /> */}
            <ClassicTheme ClientData={themeClientData} />
          </>
        );
      case "Framed":
        return (
          <>
            {/* <Banner /> */}
            <FramedTheme ClientData={themeClientData} />
          </>
        );
      case "Parallel":
        return (
          <>
            {/* <Banner /> */}
            <ParallelTheme ClientData={themeClientData} />
          </>
        );
      case "TopClassic":
        return (
          <>
            {/* <Banner /> */}
            <TopClassicTheme ClientData={themeClientData} />
          </>
        );
        
      default:
        return null;
    }
  };

  return (
    <div id="galleryDesigner" className="bg-light ">
      <Banner />
      <div className={`d-flex ${styles.content}   flex-column flex-md-row`}>
        <div className={` ${styles.sidebar} `}>
          {isDataLoaded ? (
            <SideGalleryDesigner
              isNewProject={isNewProject}
              setIsNewProject={setIsNewProject}
              themeClientData={themeClientData}
              setThemeClientData={setThemeClientData}
              defaultThemeData={defaultThemeData}
              userClientData={userClientData}
              setSelectedTheme={setSelectedTheme}
              setIsImageUploader={setIsImageUploader}
              isImageUploader={isImageUploader}
            />
          ) : null}
        </div>
        <div
          className={
            isImageUploader
              ? styles.designerBackgroundImageLoader
              : styles.designerBackground
          }
        >
          {" "}
          <div
            className={`${styles.themeContainer}  ${
              styles.scrollableContent
            } content ${isDataLoaded ? "" : "loading"}`}
            // style={{ marginLeft: "0px" }}
          >
            {/* {isDataLoaded ? <ClassicTheme ClientData={themeClientData} /> : null} */}
            {isDataLoaded ? null : <Loader className={styles.loader}></Loader>}
            {isDataLoaded && !isImageUploader ? (
              <RenderTheme></RenderTheme>
            ) : null}
            {isDataLoaded && isImageUploader ? (
              <ImageUploader ClientData={themeClientData} />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
export default GalleryDesigner;
