import React from "react";
// import "./OverviewTable.css"; // Make sure the CSS file is named 'ProfileCard.css' and is in the same directory
import styles from "./OverviewTable.module.css";
import { Heading, Divider } from "@aws-amplify/ui-react";
function OverviewTable({ data }) {
  //console.log(data);
  return (
    <>
      <div className={`${styles.overviewTable} d-flex justify-items-center  `}>
        <div className={`${styles.overviewTableHeader}  `}>
          <div style={{ textAlign: "left" }}>
            <Heading width="30vw" level={2}>
              Overview
            </Heading>{" "}
            <Divider className="mb-3" orientation="horizontal" />
          </div>
          {data.textData.map((item) => {
            return (
              <div
                key={`div_${item.text}`}
                className={`${styles.tableText} d-flex flex-column`}
              >
                <span key={`text_${item.text}`} className={`${styles.text}`}>
                  {item.text}
                </span>
                <span
                  key={`subtext_${item.text}`}
                  className={`${styles.subtext}`}
                >
                  {item.subtext}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default OverviewTable;
