// Startpage

import React, { useEffect, useState, useRef } from "react";

import styles from "./LandingPage.module.css";
import gradiant from "./gradientShape.png";
import { Image, useBreakpointValue } from "@chakra-ui/react";
import NavSection from "../../navbar/NavBar";
import DeviceImage from "./device.png";
import ContentImage from "./contentDown.png";
import DesktopImage from "./desktop.png";

import fullDesk from "./fulldesktop.png";
import { FaWineBottle } from "react-icons/fa";
import maya from "./mosque.png";
import mayaGallery from "./mosque_gallery.png";
import Carousel_1 from "./manager.png";
import Carousel_2 from "./grandmosque.png";
import Carousel_3 from "./photo_finder.png";
import ReviewSection from "./ReviewSection";
import PricingBox from "./Pricing/PricingBox/PricingBox";
import "./LandingPage.css";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import groupImage from "./group_photo.png";
export default function LandingPage() {
  const [isAlreadyScrolled, setIsAlreadyScrolled] = useState(false);
  const cardRef = useRef(null);
  const paddingRef = useRef(null);
  const [isGalleryImageActive, setIsGalleryImageActive] = useState(false);
  const [activeImage, setActiveImage] = useState(1);
  const carouselFeatureImagesRef = useRef(null);
  const handleLearnMoreClick = () => {
    console.log("Learn More Clicked");

    // scroll to carouselFeatureImagesRef
    carouselFeatureImagesRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/mosque", title: "lpmosque" });

    let scrolled = isAlreadyScrolled;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("fadeInUp");
            paddingRef.current.classList.add("padding40");
            if (!scrolled) {
              window.fbq("trackCustom", "ScrollContentPastFold");
              setIsAlreadyScrolled(true);
              scrolled = true;
            }
          }
        });
      },
      { threshold: 0.6 } // Trigger when 10% of the element is in view
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  const [hideArrow, setHideArrow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Check if the page is scrolled more than 50 pixels
      if (window.scrollY > 50) {
        setHideArrow(true);
      } else {
        // setHideArrow(false);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const ChevronRightIcon = ({
    width = "14",
    height = "14",
    fillColor = "currentColor",
  }) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill={fillColor}
        className="bi bi-chevron-right"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
        />
      </svg>
    );
  };

  const SectionHeader = ({ header, subHeader }) => {
    return (
      <div className="d-flex flex-column justify-items-center align-items-center p-1 pb-5 ">
        <div className="d-flex justify-items-center">
          <hr className="" style={{ width: "30vw", color: "#fb9239ab" }} />
        </div>
        <h2
          className="alegreya-sc-regular fs-5 text-center"
          style={{ color: "gray" }}
        >
          {header}
        </h2>
        <h3
          className="alegreya-sans-regular fs-6  px-5 text-center"
          style={{ color: "black" }}
        >
          {subHeader}
        </h3>
      </div>
    );
  };

  const FeatureCard = ({ header, subHeader, text, buttonText, onClick }) => {
    return (
      <div
        style={{ maxWidth: "400px" }}
        className="d-flex flex-column justify-items-center align-items-center p-3"
      >
        <h2 className="alegreya-sc-regular" style={{ color: "#00057B" }}>
          {header}
        </h2>
        <h3 className="alegreya-sans-sc-regular" style={{ fontSize: "1.2rem" }}>
          {subHeader}
        </h3>
        <p className="alegreya-sans-regular" style={{ textAlign: "center" }}>
          {text}
        </p>
        <button
          className="d-none d-md-block"
          style={{
            border: "1px #00057B solid",
            padding: "10px 20px",
            borderRadius: "100px",
          }}
          onClick={onClick}
        >
          <div className="alegreya-sans-sc-regular">{buttonText}</div>
        </button>
      </div>
    );
  };

  const StepCard = ({ header, subHeader, text }) => {
    return (
      <div
        style={{ maxWidth: "500px" }}
        className="d-flex flex-column justify-items-center align-items-start p-3"
      >
        <h5 className="alegreya-sc-regular" style={{ color: "black" }}>
          {header}
        </h5>
        <h3
          className="alegreya-sans-sc-regular"
          style={{ fontSize: "1.4rem", color: "#00057B" }}
        >
          {subHeader}
        </h3>
        <p className="alegreya-sans-regular" style={{ textAlign: "left" }}>
          {text}
        </p>
      </div>
    );
  };
  const PagnationDots = ({ activeImage }) => {
    return (
      <div
        className={
          styles.pagination + "d-flex justify-items-center align-items-center "
        }
        style={{ maxWidth: "400px" }}
      >
        <span
          onClick={() => setActiveImage(1)}
          className={`${styles.dot} ${
            activeImage === 1 ? styles.dotActive : ""
          }`}
        ></span>
        <span
          onClick={() => setActiveImage(2)}
          className={`${styles.dot} ${
            activeImage === 2 ? styles.dotActive : ""
          }`}
        ></span>
        <span
          onClick={() => setActiveImage(3)}
          className={`${styles.dot} ${
            activeImage === 3 ? styles.dotActive : ""
          }`}
        ></span>
      </div>
    );
  };

  const CarouselFeatureCard = ({ header, text, textAlign }) => {
    return (
      <div style={{ maxWidth: "400px" }} className="d-flex flex-column p-3">
        <h2
          className="alegreya-sans-sc-regular"
          style={{
            textAlign: textAlign ? textAlign : "left",
            color: "#00057B",
            fontSize: "18px",
            fontWeight: "700",
          }}
        >
          {header}
        </h2>
        <p
          className="alegreya-sans-sc-regular"
          style={{
            textAlign: textAlign ? textAlign : "left",
            lineHeight: "34px",
            maxWidth: "300px",
          }}
        >
          {text}
        </p>
      </div>
    );
  };

  const CarouselFeatureImages = ({ activeImage }) => {
    const isSmallScreen = activeImage === "99" ? true : false;

    const text = [
      {},
      {
        header: "Real-Time Designer Gallery",
        text: "Craft stunning galleries instantly with our real-time designer. Customize layouts, colors, and more to match your unique style.",
      },
      {
        header: "Various Gallery Themes",
        text: "Choose from a wide selection of themes to showcase your photos beautifully. From modern minimalist to classic elegance, find the perfect look for every occasion.",
      },
      {
        header: "NEW AI Photo Finder  ",
        text: "Our cutting-edge AI technology automatically identifies faces in photos, making it effortless to find specific faces.",
      },
    ];

    const BigScreen = () => {
      const [image1, setImage1] = useState(Carousel_1);
      const [image2, setImage2] = useState(Carousel_2);
      const [image3, setImage3] = useState(Carousel_3);

      const image1Ref = useRef(null);
      const image2Ref = useRef(null);
      const image3Ref = useRef(null);

      useEffect(() => {
        if (activeImage === 1) {
          // image1Ref.current.classList.add(styles.disappear);
          // image2Ref.current.classList.add(styles.disappear);
          // image3Ref.current.classList.add(styles.disappear);
          setTimeout(() => {
            if (image1Ref.current) {
              image1Ref.current.classList.add(styles.fadeInUp);
            }
          }, 0); // No delay for the first image
          setTimeout(() => {
            if (image2Ref.current) {
              image2Ref.current.classList.add(styles.fadeInUp);
            }
          }, 500); // 500ms delay for the second image

          setTimeout(() => {
            if (image3Ref.current) {
              image3Ref.current.classList.add(styles.fadeInUp);
            }
          }, 1000);

          setImage1(Carousel_1);
          setImage2(Carousel_2);
          setImage3(Carousel_3);
        } else if (activeImage === 2) {
          setTimeout(() => {
            if (image1Ref.current) {
              image1Ref.current.classList.add(styles.fadeInUp);
            }
          }, 0); // No delay for the first image
          setTimeout(() => {
            if (image2Ref.current) {
              image2Ref.current.classList.add(styles.fadeInUp);
            }
          }, 500); // 500ms delay for the second image

          setTimeout(() => {
            if (image3Ref.current) {
              image3Ref.current.classList.add(styles.fadeInUp);
            }
          }, 1000);

          setImage1(Carousel_2);
          setImage2(Carousel_3);
          setImage3(Carousel_1);
        } else if (activeImage === 3) {
          setTimeout(() => {
            if (image1Ref.current) {
              image1Ref.current.classList.add(styles.fadeInUp);
            }
          }, 0); // No delay for the first image
          setTimeout(() => {
            if (image2Ref.current) {
              image2Ref.current.classList.add(styles.fadeInUp);
            }
          }, 500); // 500ms delay for the second image

          setTimeout(() => {
            if (image3Ref.current) {
              image3Ref.current.classList.add(styles.fadeInUp);
            }
          }, 1000);

          setImage1(Carousel_3);
          setImage2(Carousel_1);
          setImage3(Carousel_2);
        }

        const interval = setInterval(() => {
          if (activeImage === 3) {
            setActiveImage(1);
          } else {
            setActiveImage(activeImage + 1);
          }
        }, 3000);
        return () => clearInterval(interval);
      }, [activeImage]);

      return (
        <>
          <div className="d-flex justify-items-center align-items-center gap-5 flex-column flex-lg-row  ">
            <div className="d-flex flex-column  offset-xl-1">
              <CarouselFeatureCard
                header={text[activeImage].header}
                text={text[activeImage].text}
              />
              <PagnationDots className="" activeImage={activeImage} />
            </div>
            <div className="d-flex flex-column col-5">
              <div className={styles.Carousel}>
                <Image
                  ref={image1Ref}
                  className={styles.Carousel_1}
                  src={image1}
                  alt="gradient"
                />
                <Image
                  ref={image2Ref}
                  className={styles.Carousel_2}
                  src={image2}
                  alt="gradient"
                />
                <Image
                  ref={image3Ref}
                  className={styles.Carousel_3}
                  src={image3}
                  alt="gradient"
                />
              </div>
            </div>
          </div>
        </>
      );
    };
    const SmallScreen = () => {
      return (
        <>
          <div className="d-flex justify-items-center align-items-center gap-3 flex-column flex-lg-row ">
            <CarouselFeatureCard
              header="Real-Time Designer Gallery"
              text="Craft stunning galleries instantly with our real-time designer. Customize layouts, colors, and more to match your unique style."
              textAlign="center"
            />
            <Image
              maxWidth={"80%"}
              paddingBottom={"50px"}
              className={styles.Carousel_1}
              src={Carousel_1}
              alt="gradient"
            />
            <CarouselFeatureCard
              header="Various Gallery Themes"
              text="Choose from a wide selection of themes to showcase your photos beautifully. From modern minimalist to classic elegance, find the perfect look for every occasion."
              textAlign="center"
            />
            <Image
              maxWidth={"80%"}
              paddingBottom={"50px"}
              className={styles.Carousel_2}
              src={Carousel_2}
              alt="gradient"
            />
            <CarouselFeatureCard
              header="NEW AI Photo Finder  "
              text="Our cutting-edge AI technology automatically identifies faces in photos, making it effortless to organize and find specific images."
              textAlign="center"
            />
            <Image
              maxWidth={"80%"}
              paddingBottom={"50px"}
              className={styles.Carousel_3}
              src={Carousel_3}
              alt="gradient"
            />
          </div>
        </>
      );
    };
    return (
      <>
        <div ref={carouselFeatureImagesRef} className="d-none d-lg-block">
          <BigScreen />
        </div>
        <div className="d-lg-none">
          <SmallScreen />
        </div>
      </>
    );
  };

  return (
    <div>
      <div className={styles.dynamicHeight}>
        <NavSection
          backgroundColor="#fb9239ab"
          fontColor="black"
          hoverColor="gray"
          className={styles.navbgColor}
        />

        <div className={styles.gradient}></div>
        <div
          className={
            styles.heroContainer +
            " d-flex flex-column flex-lg-row justify-content-center align-items-center gap-5  offset-lg-1 "
          }
        >
          <div
            classname={" abhaya-libre-bold   offset-1"}
            style={{ maxWidth: "600px" }}
          >
            <h1
              className={
                styles.heroHeader +
                " display-3 abhaya-libre-regular  text-center  text-lg-start"
              }
            >
              Celebrate Faith.

            </h1>{" "}
            <h3
              className={
                styles.heroHeaderSub +
                " display-4 abhaya-libre-regular text-lg-start  text-center   "
              }
            >
              Create Memorable Masjid Event Galleries.

            </h3>
            <p
              className={
                styles.heroText +
                "  abhaya-libre-regular text-center text-lg-start "
              }
            >
              Elevate your photography with stunning and easy-to-use client
              galleries. We use AI to allow event guests to find thier photos.
            </p>
            <div className="d-flex gap-3 justify-content-center justify-content-lg-start  align-items-center ">
              <Link to="/signUp">
                <button
                  className={
                    styles.heroButton +
                    " " +
                    styles.flexAlign +
                    " abhaya-libre-regular"
                  }
                >
                  Get Started <ChevronRightIcon />
                </button>
              </Link>
              <Link
                style={{
                  color: "inherit", // Inherits color from parent
                }}
                to="/pricing"
              >
                {" "}
                <button
                  className={`${
                    styles.heroPricingButton + " " + styles.flexAlign
                  } abhaya-libre-regular flex-align `}
                >
                  See Pricing <ChevronRightIcon />
                </button>
              </Link>
            </div>
          </div>
          <div className={styles.heroImageContainer + " "}>
            <Image src={fullDesk} alt="gradient" className={styles.heroImage} />
          </div>
        </div>
      </div>
      <section
        ref={paddingRef}
        className="d-flex flex-column justify-items-center align-items-center pt-40 pt-lg-0"
      >
        <SectionHeader
          header="PHOTO FINDER"
          subHeader="Our Photo Finder feature gives you a competitive edge. It allows guests to easily locate their photos from the event gallery, setting you apart from the rest."
        />

        {hideArrow ? null : (
          <div className={styles.arrowContainer}>
            <div className={styles.arrow}></div>
          </div>
        )}

        <div
          ref={cardRef}
          className={`d-flex flex-column gap-0 gap-md-5 flex-lg-row justify-content-center align-items-center ${styles.featureCardPadding}`}
          style={{ paddingBottom: "40px" }}
        >
          <div>
            {" "}
            <StepCard
              header="Step 1"
              subHeader="Guests Receive a Link to the Masjid Event Gallery"
              text="After the event, attendees will receive a link to the Masjid event gallery via email, text, or a QR code. This link gives them immediate access to all the photos from the event in one convenient location."
            />
            <StepCard
              header="Step 2"
              subHeader="Real-Time Gallery Editor"
              text="Once in the gallery, guests simply take a selfie using their device's camera. The AI-powered Photo Finder then compares their selfie to the event photos, making the process quick and easy."
            />
            <StepCard
              header="Step 3"
              subHeader="AI-Powered Face Detection"
              text="Within seconds, the gallery will show all the photos where the attendee appears. They can easily view, download, and share their cherished moments from the Masjid event."
            />
          </div>

          <div>
            <Image
              maxWidth={useBreakpointValue({ base: "90vw", lg: "40vw" })}
              src={groupImage}
              alt="gradient"
              className={styles.heroImage}
            />
          </div>
        </div>
      </section>
      <section
        ref={paddingRef}
        className="d-flex flex-column justify-items-center align-items-center pt-40 pt-lg-0"
      >
        <SectionHeader
          header="ELEVATE YOUR PHOTOGRAPHY BUSINESS"
          subHeader="The ultimate solution for modern photographers to share and deliver their work online."
        />

        <div
          ref={cardRef}
          className={`d-flex flex-column flex-lg-row ${styles.featureCardPadding}`}
          style={{ paddingBottom: "40px", visibility: "hidden" }}
        >
          {" "}
          <FeatureCard
            header="Simple"
            subHeader="Easy Photo Management"
            text="Our user-friendly interface simplifies photo upload, organization, and sharing. Whether you're tech-savvy or just getting started, our platform ensures a smooth experience."
            buttonText="Learn More"
            onClick={handleLearnMoreClick}
          />
          <FeatureCard
            header="Customize"
            subHeader="Real-Time Gallery Editor"
            text="Tailor your galleries on the fly with our real-time editor, ensuring each presentation meets your unique style and client needs."
            buttonText="Learn More"
            onClick={handleLearnMoreClick}
          />
          <FeatureCard
            header="Photo Finder"
            subHeader="AI-Powered Face Detection"
            text="Event attendees can effortlessly capture selfies and locate their photos with our advanced AI technology that identifies faces in both individual and group shots."
            buttonText="Learn More"
            onClick={handleLearnMoreClick}
          />
        </div>
      </section>
      {/* <div style={{ position: "relative" }}>
        <div className={styles.gradient2}></div>
      </div> */}

      <section className="d-flex flex-column justify-items-center align-items-center ">
        <SectionHeader
          header="DESIGN"
          subHeader="Provide each of your clients with a personalized online photo gallery, complete with a stunning cover and layout right from the start."
        />
        <div
          className="d-flex gap-0 pb-25 justify-content-center"
          style={{ maxWidth: "700px", width: "80%" }}
        >
          <button
            onClick={() => setIsGalleryImageActive(false)}
            className={`${
              styles.designButton
            } alegreya-sans-sc-regular flex-grow-1 ${
              isGalleryImageActive
                ? styles.designButton_active
                : styles.designButton
            }`}
          >
            {" "}
            Custom Cover
          </button>
          <button
            onClick={() => setIsGalleryImageActive(true)}
            className={`alegreya-sans-sc-regular flex-grow-1 ${
              isGalleryImageActive
                ? styles.designButton
                : styles.designButton_active
            }`}
          >
            {" "}
            The Gallery
          </button>
        </div>
        <Image
          style={{ maxWidth: useBreakpointValue({ base: "90vw", lg: "60vw" }) }}
          src={isGalleryImageActive ? mayaGallery : maya}
          alt="gradient"
          className={styles.heroImage}
        />
      </section>
      <section
        style={{ minHeight: useBreakpointValue({ base: "", lg: "700px" }) }}
        className="pt-50 pb-200"
      >
        {" "}
        <SectionHeader
          header="FEATURES"
          subHeader="Our platform is designed to help you grow your photography business."
        />
        <CarouselFeatureImages activeImage={activeImage} />
      </section>

      <section className="pb-40"
      style={{ paddingTop: useBreakpointValue({ base: "10px",md:"10px" ,lg: "70px" })}}>
        <SectionHeader
          header="Our Clients Speak"
          subHeader="We have been working with clients around the world."
        />
        <ReviewSection />
      </section>

      <section className="">
        <SectionHeader
          header="Pay Per Gallery"
          subHeader="Photographers can host their clients' photo galleries without the need for a subscription. Simply purchase each gallery as needed."
        />

        <div className="d-flex justify-content-center align-items-center gap-2">
          <PricingBox />
        </div>
      </section>

      <section
        className={`d-flex flex-column flex-sm-row justify-content-center align-items-center gap-3 gap-sm-5 ${styles.gradient1}`}
        style={{ height: "400px" }}
      >
        <div classname={" abhaya-libre-bold "} style={{ maxWidth: "" }}>
          <h1 className={styles.heroHeader + " display-3 abhaya-libre-regular"}>
            Capture the Moment.
          </h1>{" "}
          <h1
            className={styles.heroHeaderSub + "display-4 abhaya-libre-regular"}
          >
            Enhance Your Masjid Events with AI-Powered Photo Galleries.
          </h1>
        </div>
        <div className="d-flex gap-3">
          <Link to="/signUp">
            <button
              className={
                styles.heroButton +
                " " +
                styles.flexAlign +
                " fs-6 abhaya-libre-regular " +
                styles.slightlyGettingBigger
              }
            >
              Create Gallery <ChevronRightIcon />
            </button>
          </Link>
        </div>
      </section>
    </div> // Ending div
  );
}
