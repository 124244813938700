import React from "react";

function RedirectPage({location}) {

    window.location.href = location
  return (
    <div>
      <h1>RedirectPage</h1>
    </div>
  );
}

export default RedirectPage;
