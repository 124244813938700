import React from "react";
import styles from "./GalleryMessage.module.css";

function GalleryMessage({ message }) {
  return (
    <div className="d-flex justify-items-center">
      {/* <h1 style={{fontFamily: "lato",letterSpacing: ".05em", fontWeight: "600", color: "var(--amplify-netural-60"}}>{message}</h1> */}
      <div className={`${styles.btnPortfolio} m-3 `}>{message}</div>
    </div>
  );
}

export default GalleryMessage;
