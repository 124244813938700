import { Outlet } from "react-router-dom";
import NavBar from "../components/navbar/NavBar-Archive";
import Footer from "../components/Footer/Footer";
import NavSection from "../components/navbar/NavBar";
const MarketingLayout = () => {
  return (
    <>
      <div className="root-layout-content">
        {/* <NavBar /> */}
        {/* <NavSection /> */}
        <div className="root-layout-container">
          <div className=" marketing-layout-outlet">
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default MarketingLayout;
