import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";
import { TokenProvider, decodeJWT } from "aws-amplify/auth";

async function currentSession() {
  try {
    const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
    return idToken;
  } catch (err) {}
}

export async function getIdentityId() {
  try {
    const session = await fetchAuthSession();
    if (session) {
      const identityId = session.identityId;
      return identityId;
    }
  } catch (error) {
    console.error("Fetch auth session failed with error:", error);
  }
}


export const getUserData = async function () {
  //console.log(`${bucket} start_range-${start_range}`)
  //console.log(`end_range-${end_range}`)
  const token = await currentSession();
  const apiGateway =
    process.env.REACT_APP_ZMEDIA_API_GATEWAY;
  const requestUrl = `${apiGateway}/api/user?` + new URLSearchParams({});

  return await fetch(requestUrl, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  })
    .then((data) => {
      return data.json();
    })
    .catch((error) => {
      //   console.error(error);
    });
};
