import { ReactNode } from "react";
import { useRef } from "react";
import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
  StylesProvider,
} from "@chakra-ui/react";
import {
  FREE_SIZE_THRESHOLD,
  BASIC_SIZE_THRESHOLD,
  PRO_SIZE_THRESHOLD,
} from "../../../../../api/payment";
import styles from "./PricingBox.module.css";
import { FaCheckCircle } from "react-icons/fa";
import { FaTimesCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
function PriceWrapper({ children }) {
  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: "center", lg: "flex-start" }}
      borderColor={useColorModeValue("gray.200", "gray.500")}
      borderRadius={"xl"}
    >
      {children}
    </Box>
  );
}

export default function ThreeTierPricing({
  paymentGallerySize,
  paymentDisplay,
  onClickFree,
  onClickPro,
  onClickBasic,
}) {
  const freeBoxRef = useRef(null);
  const basicBoxRef = useRef(null);
  const proBoxRef = useRef(null);

  return (
    <Box>
      <Stack
        direction={{ base: "column", md: "row" }}
        textAlign="center"
        justify="center"
        px={{ base: 2 }}
        spacing={{ base: 4, lg: 10 }}
        py={5}
      >
        <PriceWrapper>
          <Box py={4} px={12}>
            <Text fontWeight="500" fontSize="2xl">
              Free
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="lg" fontWeight="600">
                $
              </Text>
              <Text fontSize="4xl" fontWeight="900">
                0
              </Text>
              <Text fontSize="lg" color="gray.500">
                /gallery
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue("gray.50", "gray.700")}
            py={4}
            borderBottomRadius={"xl"}
          >
            <List spacing={3} textAlign="start" fontSize={"12px"} px={12}>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Online gallery access for one year
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                {FREE_SIZE_THRESHOLD / 1024 ** 3}GB Photo Storage
              </ListItem>
              <ListItem>
                <ListIcon as={FaTimesCircle} color="red.500" />
                AI Photo Finder Feature
              </ListItem>
            </List>
            <Box w="80%" pt={7}>
              {paymentGallerySize < FREE_SIZE_THRESHOLD && (
                <Link to="/dashboard" onClick={onClickFree}>
                  <Button
                    fontSize={"sm"}
                    w="full"
                    colorScheme="blue"
                    variant="outline"
                  >
                    {paymentDisplay
                      ? "Select Free Gallery"
                      : "Create Free Gallery"}
                  </Button>
                </Link>
              )}
            </Box>
          </VStack>
        </PriceWrapper>

        <PriceWrapper>
          <Box position="relative">
            <Box
              position="absolute"
              top="-16px"
              left="50%"
              style={{ transform: "translate(-50%)" }}
            >
              <Text
                textTransform="uppercase"
                bg={useColorModeValue("red.300", "red.700")}
                px={3}
                py={1}
                color={useColorModeValue("gray.900", "gray.300")}
                fontSize="sm"
                fontWeight="600"
                rounded="xl"
              >
                Most Popular
              </Text>
            </Box>
            <Box ref={proBoxRef} py={4} px={12}>
              <Text fontWeight="500" fontSize="2xl">
                Pro
              </Text>
              <HStack justifyContent="center">
                <Text fontSize="xl" fontWeight="600">
                  $
                </Text>
                <Text fontSize="4xl" fontWeight="900">
                  14.99
                </Text>
                <Text fontSize="lg" color="gray.500">
                  /gallery
                </Text>
              </HStack>
            </Box>
            <VStack
              bg={useColorModeValue("gray.50", "gray.700")}
              py={4}
              borderBottomRadius={"xl"}
            >
              <List spacing={3} textAlign="start" fontSize={"12px"} px={12}>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Online gallery access for one year
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  {PRO_SIZE_THRESHOLD / 1024 ** 3}GB Photo Storage
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  AI Photo Finder Feature
                </ListItem>
              </List>
              <Box w="80%" pt={7}>
                {paymentGallerySize < PRO_SIZE_THRESHOLD && (
                  <Link onClick={onClickPro}>
                    <Button fontSize={"sm"} w="full" colorScheme="blue">
                      {paymentDisplay
                        ? "Select Pro Gallery"
                        : "Create Pro Gallery"}{" "}
                    </Button>
                  </Link>
                )}
              </Box>
            </VStack>
          </Box>
        </PriceWrapper>

        <PriceWrapper>
          <Box ref={basicBoxRef} py={4} px={12}>
            <Text fontWeight="500" fontSize="2xl">
              Basic
            </Text>

            <HStack justifyContent="center">
              <Text fontSize="xl" fontWeight="600">
                $
              </Text>
              <Text fontSize="4xl" fontWeight="900">
                9.99
              </Text>
              <Text fontSize="lg" color="gray.500">
                /gallery
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue("gray.50", "gray.700")}
            py={4}
            borderBottomRadius={"xl"}
          >
            <List spacing={3} textAlign="start" fontSize={"12px"} px={12}>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Online gallery access for one year
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                {BASIC_SIZE_THRESHOLD / 1024 ** 3}GB Photo Storage
              </ListItem>
              <ListItem>
                <ListIcon as={FaTimesCircle} color="red.500" />
                AI Photo Finder Feature
              </ListItem>
            </List>
            <Box w="80%" pt={7}>
              {paymentGallerySize < BASIC_SIZE_THRESHOLD && (
                <Link to="/dashboard" onClick={onClickBasic}>
                  {" "}
                  <Button
                    fontSize={"sm"}
                    w="full"
                    colorScheme="blue"
                    variant="outline"
                  >
                    {paymentDisplay
                      ? "Select Basic Gallery"
                      : "Create Basic Gallery"}{" "}
                  </Button>
                </Link>
              )}
            </Box>
          </VStack>
        </PriceWrapper>
      </Stack>

      <div
        className="d-flex justify-items-center align-items-center gap-1"
        style={{ marginTop: "-20px" }}
      >
        <span style={{ fontSize: "14px" }}>Need More?</span>
        <Button
          fontSize={"sm"}
          variant={"link"}
          as={"a"}
          href={"/contact"}
          bg={"white"}
          color={"black"}
          style={{ fontWeight: "700" }}
          _hover={{
            bg: "white",
            color: "gray",
          }}
        >
          Contact Us
        </Button>
      </div>
    </Box>
  );
}
