import React, { useEffect, useState, useRef } from "react";
import { copy, list } from "aws-amplify/storage";
import "./ProjectTable.css";
import styles from "./ProjectTable.module.css";
import { deleteUserProject, removeS3ProjectFolder } from "../../api/projects";
import { Link } from "react-router-dom";
import { getUserData } from "../../api/user";
import { getUserProjects } from "../../api/projects";
import { getStripeGalleryPayment } from "../../api/payment";
import { IconView, IconEdit, IconUnlock, IconDelete } from "./IconComponents";
import ReactGA from "react-ga4";
import { Modal } from "react-bootstrap";
import {
  Button,
  Pagination,
  usePagination,
  ThemeProvider,
  Theme,
} from "@aws-amplify/ui-react";
import { border } from "@chakra-ui/react";
import { act } from "react";
import ThreeTierPricing from "../Pages/LandingPage_3/Pricing/PricingBox/PricingBox";

const paginationTheme = {
  name: "pagination-theme",
  tokens: {
    components: {
      pagination: {
        current: {
          backgroundColor: { value: "#00057B" },
        },
        button: {
          focus: {
            color: { value: "yellow" },
          },
          hover: {
            backgroundColor: { value: "{colors.neutral.40}" },
            color: { value: "black" },
          },
        },
      },
    },
  },
};

function ProjectTable({}) {
  const [username, setUsername] = useState("");
  const [loadingStates, setLoadingStates] = useState({});
  const [tableData, setTableData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const selectedPricingRef = useRef("nuller");
  useEffect(() => {
    if (projectData.length === 0) {
      return;
    }

    let tempDataTable = [];
    projectData.forEach((gallery) => {
      let tableRow = {
        "Gallery Name": gallery.SK.split("::")[1],
        Status: gallery.IsActive ? "Active" : "Inactive",
        Expired: gallery.IsExpired ? true : false,
        Processing: gallery.IsProcessing ? true : false,
        ExpireDate: gallery.ExpireDate ? gallery.ExpireDate : "N/A",
      };
      tempDataTable.push(tableRow);
    });
    setTableData([...tempDataTable]);
    setIsDataLoaded(true);
  }, [projectData]);

  // useEffect to getUserInfo from the server
  useEffect(() => {
    const getProjectInfo = async () => {
      try {
        const data = await getUserProjects().then((data) => {
          // convertAPIResponseToTableData(data);
          setProjectData([...data.Items]);
          // Check number of projects
          if (data.Items.length === 0) {
            setIsDataLoaded(true);
          }
        });
      } catch (err) {
        console.error(err);
      }
    };
    const getUsername = async () => {
      try {
        const response = await getUserData().then((data) => {
          setUsername(data.Item.preferred_username);
        });
      } catch (err) {
        console.error(err);
      }
    };

    getUsername();
    getProjectInfo();
  }, []);

  useEffect(() => {
    setTableData(tableData);
    setInactiveProjects(
      tableData.filter(
        (project) => project.Status === "Inactive" && !project.Expired
      )
    );
    setActiveProjects(
      tableData.filter(
        (project) => project.Status === "Active" && !project.Expired
      )
    );
    setExpiredProjects(tableData.filter((project) => project.Expired === true));
  }, [tableData]);

  const handleActivateProject = async (e) => {
    setShowModal({
      event: e,
      show: true,
      onConfirm: () => lockUserProject(e),
    });
  };
  async function getAllItems(project_name) {
    try {
      const response = await list({
        prefix: project_name + "/originalImages/",
        options: {
          listAll: true,
          accessLevel: "private", // can be 'private', 'protected', or 'guest' but defaults to `guest`
        },
      });
      return response.length;
    } catch (error) {
      console.error("Error listing items:", error);
    }
  }
  const lockUserProject = async (e) => {
    // return;

    setShowModal(false);
    const gallery_name = e.target.id;
    setLoadingStates((prevStates) => ({ ...prevStates, [gallery_name]: true }));
    let gallery_size = 9999999999999;

    try {
      gallery_size = await list({
        prefix: gallery_name + "/originalImages/",
        options: {
          listAll: true,
          accessLevel: "private", // can be 'private', 'protected', or 'guest' but defaults to `guest`
        },
      });
      // hide action icons
      const url = await getStripeGalleryPayment(
        e.target.id,
        selectedPricingRef.current,
        gallery_size
      )
        .then((data) => {
          if (data.message === "SUCCESS") {
            //console.log("Success");
          }
          return data.url;
        })
        .catch((error) => {
          //console.log(error);
        });

      if (url) {
        window.location.href = url;
      }
      //console.log(url);
    } catch (error) {
      //console.log("Error: Activating project", error);
    }
  };

  const handleDeleteProject = async (e) => {
    if (window.confirm("Are you sure you want to delete this project?")) {
      const gallery_name = e.target.id.replace("_deleteIcon", "");

      try {
        const response = await deleteUserProject(gallery_name).then((data) => {
          // Delete theme  from tableData
          const newTableData = tableData.filter(
            (row) => row["Gallery Name"] !== gallery_name
          );
          setTableData([...newTableData]);
        });

        removeS3ProjectFolder(gallery_name);
        // setTableData(response);
        // window.location.reload();

        // remove project from tableData
      } catch (error) {
        //console.log(error);
      }

      // Refresh the page
    }
  };

  const IconButtonExample = ({ row, isActive }) => {
    return (
      <div className="d-flex flex-column gap-1  flex-md-row justify-items-center ">
        {" "}
        {isActive ? (
          <>
            {username !== "" ? (
              <>
                <Link
                  to={`/${username}/${row["Gallery Name"]}`}
                  style={{ display: "contents" }}
                >
                  <Button borderRadius={"16px"} size="large">
                    <div className="d-block d-lg-none  ">
                      <IconView />
                    </div>
                    <div className={`${styles.IconText} d-none d-lg-block`}>
                      {"View"}{" "}
                    </div>
                  </Button>
                </Link>
              </>
            ) : null}
          </>
        ) : (
          <>
            <Link
              to="/gallery-designer"
              state={{ gallery_name: row["Gallery Name"] }}
              style={{ display: "contents" }}
            >
              <Button borderRadius={"16px"} size="large">
                <div>
                  <div className="d-block d-lg-none hover  ">
                    <IconEdit />
                  </div>
                  <div className={`${styles.IconText} d-none d-lg-block`}>
                    {"Edit"}{" "}
                  </div>
                </div>
              </Button>{" "}
            </Link>

            <Button
              borderRadius={"16px"}
              id={row["Gallery Name"]}
              isLoading={loadingStates[row["Gallery Name"]]}
              onClick={handleActivateProject}
              size="large"
            >
              <div className="d-block d-lg-none ">
                <IconUnlock />
              </div>
              <div
                style={{ pointerEvents: "none" }}
                className={`${styles.IconText} d-none d-lg-block`}
              >
                {"Activate"}{" "}
              </div>
            </Button>
          </>
        )}
        <Button
          borderRadius={"16px"}
          id={row["Gallery Name"] + "_deleteIcon"}
          onClick={handleDeleteProject}
          key={row["Gallery Name"]}
          size="large"
        >
          <div className="d-block d-lg-none ">
            <IconDelete />
          </div>
          <div className={`${styles.IconText} d-none d-lg-block`}>
            {"Delete"}{" "}
          </div>
        </Button>{" "}
      </div>
    );
  };

  const LoadingTable = () => {
    return (
      <>
        {[...Array(5)].map((_, index) => (
          <tr
            key={index}
            style={{
              height: "50px",
              backgroundColor: index % 2 === 0 ? "#ffffff" : "#f2f2f2", // Alternating background colors
            }}
          >
            <td></td>
            <td></td>
            <td></td>
          </tr>
        ))}
      </>
    );
  };
  const ProjectCard = ({
    tableName,
    projectName,
    projectStatus,
    projectProcessing,
    projectExpireDate,
  }) => {
    return (
      <div>
        <div
          className={`${styles.projectCard} d-flex justify-content-between align-items-center p-3`}
        >
          <div className="d-flex flex-column justify-content-center">
            <span>
              <b>{projectName}</b>
            </span>
            <span className={styles.subText}>
              {projectStatus === "Active" ? (
                <>
                  <b>Expire Date: </b> {projectExpireDate}
                </>
              ) : (
                <>
                  <b>Status:</b>{" "}
                  {projectProcessing ? "Processing" : projectStatus}
                </>
              )}
            </span>{" "}
          </div>
          <span>
            {projectProcessing ? null : (
              <IconButtonExample
                row={{ "Gallery Name": projectName }}
                isActive={projectStatus === "Active" ? true : false}
              />
            )}
          </span>
        </div>
      </div>
    );
  };

  const LoadingProjectCard = () => {
    return (
      <div>
        <div
          className={`${styles.projectCard} d-flex justify-content-between align-items-center p-3 mb-1`}
          style={{ filter: "blur(1.5px)" }}
        >
          <div className="d-flex flex-column justify-content-csenter">
            <span>
              <b>Loading....</b>
            </span>
            <span>
              <b>Status:</b> Loading....
            </span>
          </div>
        </div>
      </div>
    );
  };

  const ProjectCardTable = ({ tableHeader, projectData }) => {
    // Assuming activeProjects is meant to be derived from projectData
    const [currentPageIndex, setCurrentPageIndex] = React.useState(1);
    const totalPages = Math.ceil(projectData.length / 5);
    const totalProjects = projectData.length ? projectData.length : 0;
    const start = currentPageIndex * 5 - 5;
    const end = currentPageIndex * 5;
    const handleNextPage = () => {
      //console.log("handleNextPage");
      setCurrentPageIndex(currentPageIndex + 1);
    };

    const handlePreviousPage = () => {
      //console.log("handlePreviousPage");
      setCurrentPageIndex(currentPageIndex - 1);
    };

    const handleOnChange = (newPageIndex, prevPageIndex) => {
      setCurrentPageIndex(newPageIndex);
    };
    return (
      // Return only if projectData is not empty

      <div className="col-sm-5 col-8">
        <h3 className="alegreya-sans-sc-regular fs-4">
          {tableHeader} - {totalProjects}{" "}
        </h3>

        <>
          {projectData.slice(start, end).map((project, index) => (
            <div key={index} className="pb-3">
              <ProjectCard
                projectName={project["Gallery Name"]}
                projectStatus={project.Status}
                projectProcessing={project.Processing}
                projectExpireDate={project.ExpireDate}
              />
            </div>
          ))}
          {projectData.length === 0 && !isDataLoaded ? (
            <div className="pb-3">
              <LoadingProjectCard />
              <LoadingProjectCard />
              <LoadingProjectCard />
            </div>
          ) : null}

          {projectData.length !== 0 ? (
            <ThemeProvider theme={paginationTheme} colorMode="light">
              <Pagination
                currentPage={currentPageIndex}
                totalPages={totalPages}
                siblingCount={1}
                onNext={handleNextPage}
                onPrevious={handlePreviousPage}
                onChange={handleOnChange}
              />
            </ThemeProvider>
          ) : null}
        </>
      </div>
    );
  };

  const ChevronRightIcon = ({
    width = "14",
    height = "14",
    fillColor = "currentColor",
  }) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill={fillColor}
        className="bi bi-chevron-right"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
        />
      </svg>
    );
  };

  function convertBtyesToGB(bytes) {
    return bytes / (1024 * 1024 * 1024);
  }
  const [inactiveProjects, setInactiveProjects] = useState([]);
  const [activeProjects, setActiveProjects] = useState([]);
  const [expiredProjects, setExpiredProjects] = useState([]);

  function ConfirmModal(props) {
    // let gallery_size = 0 * 1024 * 1024 * 1024;
    const [gallerySize, setGallerySize] = useState(0);
    const [gallerySizeGB, setGallerySizeGB] = useState(0);
    if (props.event) {
      //console.log("props.event");
      //console.log(props.event.target.id);
    }
    useEffect(() => {
      async function fetchGallerySize() {
        if (!props.event) {
          return;
        }
        try {
          const gallery_name = props.event.target.id; // Define or pass this appropriately
          const result = await list({
            prefix: gallery_name + "/originalImages/",
            options: {
              listAll: true,
              accessLevel: "private", // can be 'private', 'protected', or 'guest' but defaults to `guest`
            },
          });
          //console.log("Gallery size:", result);
          let tempSize = 0;
          result.items.map((item) => {
            tempSize = tempSize + item.size;
          });

          // Convert to GB
          setGallerySizeGB(convertBtyesToGB(tempSize).toFixed(2));
          setGallerySize(tempSize); // Assuming `result` is the size, adjust according to the actual API response
        } catch (error) {
          //console.log("Error: Activating project", error);
        }
      }

      fetchGallerySize();
    }, []); // Empty dependency array means this effect runs once on mount

    return (
      <Modal
        dialogClassName={styles.customModal}
        show={props.show}
        onHide={props.onHide}
      >
        {" "}
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.body}</Modal.Body>
        <Modal.Body>Current Gallery Size - {gallerySizeGB} GB</Modal.Body>
        <Modal.Body></Modal.Body>
        <ThreeTierPricing
          onClickPro={() => {
            selectedPricingRef.current = "pro";
            props.onConfirm();
          }}
          onClickFree={() => {
            selectedPricingRef.current = "free";
            props.onConfirm();
          }}
          onClickBasic={() => {
            selectedPricingRef.current = "basic";
            props.onConfirm();
          }}
          paymentGallerySize={gallerySize}
          paymentDisplay={true}
        />{" "}
        <Modal.Footer className="d-flex justify-content-center p-3 mt-3"></Modal.Footer>
      </Modal>
    );
  }

  return (
    <div style={{ paddingTop: "50px" }} className="">
      <ConfirmModal
        show={showModal.show}
        event={showModal.event}
        title={"Activate Project?"}
        body={"Once you activate project it CANNOT BE EDITED! Continue?"}
        onConfirm={showModal.onConfirm}
        onHide={() => {
          setShowModal(false);
        }}
      />

      <div className="pb-100">
        <div className="d-flex flex-column flex-sm-row justify-content-between pb-5 px-5">
          <h1 className="alegreya-sans-sc-regular display-6  ">
            Project information
          </h1>

          {projectData.length !== 0 && isDataLoaded ? (
            <Link to="/gallery-designer" state={{ from: "occupation" }}>
              <button
                className={
                  styles.heroButton +
                  " " +
                  styles.flexAlign +
                  " abhaya-libre-regular"
                }
              >
                Create New Gallery <ChevronRightIcon />
              </button>{" "}
            </Link>
          ) : null}
        </div>

        <div className="d-flex  offset-sm-1 align-items-start  gap-5 flex-wrap flex-column  align-items-center align-items-sm-start flex-sm-row">
          <ProjectCardTable
            tableHeader={"Inactive Projects"}
            projectData={inactiveProjects}
          />
          <ProjectCardTable
            tableHeader={"Active Projects"}
            projectData={activeProjects}
          />
          {projectData.length === 0 && isDataLoaded ? null : (
            <ProjectCardTable
              tableHeader={"Expired Projects"}
              projectData={expiredProjects}
            />
          )}
        </div>
        {projectData.length === 0 && isDataLoaded ? (
          <div className="pt-100">
            <div className="d-flex flex-column justify-items-center align-items-center ">
              <h1>Ready to create a beatiful gallery?</h1>
              <Link to="/gallery-designer" state={{ from: "occupation" }}>
                <button
                  className={
                    styles.heroButton +
                    " " +
                    styles.flexAlign +
                    " abhaya-libre-regular"
                  }
                >
                  Create New Gallery <ChevronRightIcon />
                </button>{" "}
              </Link>
            </div>
          </div>
        ) : null}
        {/* <div className="d-flex flex-column gap-3">
        <ProjectCard projectName="Zachary Sherin" projectStatus="Inactive" />
        <ProjectCard projectName="Zachary Sherin" projectStatus="Inactive" />
        <ProjectCard projectName="Zachary Sherin" projectStatus="Inactive" />
        <ThemeProvider theme={paginationTheme} colorMode="light">
          <Pagination currentPage={1} totalPages={10} siblingCount={1} />
        </ThemeProvider>{" "}
      </div> */}
      </div>
    </div>
  );
}

export default ProjectTable;
