import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "@aws-amplify/ui-react";
import styles from "./PrivacyPage.module.css";
import ReactGA from "react-ga4";

function PrivacyPage() {
  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.send({ hitType: "pageview", page: "/privacy", title: "Privacy Policy" });
  }, []);

  return (
    <div
      style={{ paddingTop: "80px", minHeight: "80vh", padding: "20px" }}
      className="d-flex flex-column justify-content-center align-items-center"
    >
      <h1 className={styles.heading}>Privacy Policy</h1>
      
      <div className={styles.content}>
        <p>
          Your privacy is important to us. This privacy policy explains how we collect,
          use, and protect your personal information when you use our website. By using 
          our services, you consent to the practices outlined in this policy.
        </p>
        
        <h2>Information We Collect</h2>
        <p>
          We may collect personal information such as your name, email address, and other 
          contact details when you submit a form or use our services.
        </p>
        
        <h2>How We Use Your Information</h2>
        <p>
          The information we collect is used to provide and improve our services, 
          communicate with you, and ensure a seamless experience on our platform. 
          We do not share your personal information with third parties unless required 
          by law or necessary for providing our services.
        </p>
        
        <h2>Your Rights</h2>
        <p>
          You have the right to access, update, or delete your personal information at 
          any time. If you have any questions or concerns about our privacy practices, 
          please contact us.
        </p>
      </div>
      
      <Link to="/">
        <Button fontSize={"sm"} className={styles.button} variant="outline" colorScheme="blue">
          Go Back to Home
        </Button>
      </Link>
    </div>
  );
}

export default PrivacyPage;
